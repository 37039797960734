export default {
    'first_name': '',
    'last_name': '',
    'email': '',
    'contact_number': '',
    'cp_first_name': '',
    'cp_last_name': '',
    'cp_email': '',
    'cp_contact_number': '',
    'cp_gender': '',
    'dob': '',
    'social_security_number': '',
    'guardian_first_name': '',
    'guardian_last_name': '',
    'guardian_email': '',
    'guardian_contact_number': '',
    'gender': ''
}