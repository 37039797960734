// App settings
let url = ""
let build = "live"
if (build === "live") {
    url = "https://api.studentstore.se"
} else {
    url = "https://stagingapi.studentstore.se"
}
export const apiUrl = url + "/api"
export const importUrl = url + "/export/";
export const placeApiKey = "AIzaSyCa3cmHM3EN6lfzeGhF0utqnpiXDl4rHww"


export const metaValues = {
    title: 'Student Store',
    description: 'Student Store Description',
    keywords: 'Student Products, Services, Books, Contests'
}
export const customSelectStyles = {
    control: base => ({
        ...base,
        minHeight: 34,
        fontSize: 14,
        borderRadius: 0
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        padding: 4
    }),
    singleValue: (provided, state) => ({
        ...provided,
    }),
    clearIndicator: (provided, state) => ({
        ...provided,
        padding: 4
    }),
};