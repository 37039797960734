import React, { useEffect, useRef, useState } from 'react';

const LoadingBox = (props) => {
    let el = useRef()
    const [h, setH] = useState(150)
    const height = (props.height !== undefined) ? props.height : (props.absolute) ? h : 150

    useEffect(() => {
        setH(el.current?.offsetHeight)
    }, [])

    useEffect(() => {
        setH(el.current?.offsetHeight)
    }, [props])

    return (
        <>
            {
                (props.loading) ?
                    <div ref={el} style={props.style} className={(props.absolute) ? 'loadingBoxAbsolute' : ''}>
                        <div style={{ height: height }} className="row align-items-center justify-content-center">
                            <div className="col-1 justify-content-center align-self-center">
                                <div>
                                    <i className="fa fa-2x fa-spinner fa-pulse"></i>
                                    <p className='loader-message'>
                                        {props?.message}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
            }
        </>
    );
};

export default LoadingBox;