/* eslint-disable import/no-anonymous-default-export */


export default {
    "en": {
        "labels": {
            "user_types": {
                "student": "Student",
                "service_provider": "Service Provider"
            },
            "user_types_filter": {
                "student": "Student",
                "company": "Company"
            },
            "ratings": {
                "view_all": "View All"
            },
            "service_details": {
                "products": "Products",
                "services": "Services",
                "books": "Books",
                "address": "Address",
                "ratings": "Review and ratings",
                "licence": "Business Licence",
                "read_less": "Read Less",
                "read_more": "Read More"
            },
            "home_page": {
                "view_all": "View All",
                "best_of_library": "Best Of Library",
                "best_service_providers": "Best Service Provider",
                "new_job_vacancies": "New Job Vacancies",
                "new_products": "New Products"
            },
            "stripePaymentGatewayAPIKeys": {
                "CURRENCY": "SEK",
                "STRIPE_PUBLISHABLE_KEY": "pk_test_snDrNKYzsomUkUIG7HX3VSr3",
                "Secret_key": "sk_test_519T7jfD6j8NkE89Ko1JaxF7qs6zURAqj4qmr2a2v33LE8MDxYq2CAhMwaZOQKkV5EA7ZZfApqYSPRZRVb1piukao001xGmsBCv"
            },
            "login": {
                "forgot_password": "Forgot Password",
                "password": "Password",
                "phone_number": "Phone Number",
                "remember_me": "Remember Me",
                "sign_in": "Sign In",
                "sign_up_text": "You don't have an account? Sign up",
                "terms_and_policy": "Terms of Use, Privacy Policy",
                "welcome_text": "Welcome to Student Store",
                "welcome_back": "Welcome Back",
                "email_phone": "Email or Phone",
                "remember_password": "Remember Password",
                "service_provider": "Service Provider",
                "student": "Student",
                "buyer": "Buyer",
                "opportunity_text": "Don't miss your next opportunity. Sign in to stay updated on your professional world."
            },
            "SignUp": {
                "continue_as_service_provider": "Continue As Service Provider",
                "continue_as_student": "Continue As Student",
                "continue_as_buyer": "Continue As Buyer",
                "welcome_text": "Welcome to Student Store"
            },
            "add_new_vacancy": {
                "meta_title": "Meta Title",
                "meta_keyword": "Meta Keywords",
                "meta_description": "Meta Description",
                "some": "some",
                "application_period": "Application Period",
                "duties_and_responsibilities": "Duties and Responsibilities",
                "from": "From",
                "job_address": "Job Address",
                "job_description": "Job Description",
                "job_env": "Job Environment",
                "job_hours": "Job Hours",
                "job_start_date": "Job Start Date",
                "languages": "Languages",
                "nice_to_have_skills": "Nice To Have Skills",
                "page_title": "Add New Vacancy",
                "publish_vacancy": "Publish Vacancy",
                "save": "Save",
                "skills": "Skills",
                "to": "To",
                "work_type": "Work Type",
                "years_of_exp": "Years of Experience",
                "duration": "Duration",
                "category": "Category",
                "sub_category": "Sub Category",
                "job_title": "Job Title - Position",
                "edit_vacancy": "Edit Vacancy",
                "hours": "Hours",
                "start": "Start",
                "till": "Till",
                "view_on_map": "View On Map",
                "view_applicants": "View Applicants",
                "apply_now": "Apply Now",
                "application_will_start": "Applications will start from",
                "already_applied": "Already Applied",
                "complete_cv": "Please complete your CV to apply",
                "update_cv": "Update My CV"
            },
            "address": {
                "address": "Address",
                "add_address": "Add New Address",
                "default_address": "Set As Default Address",
                "pincode": "Postal Code",
                "save": "Save",
                "search": "Search",
                "title": "Address Title (Home. Office, ...)",
                "my_addresses": "My Addresses",
                "default": "default",
                "delete": "Delete",
                "cancel": "Cancel"
            },
            "card_form": {
                "please_enter_your_card_information": "Please enter your card information",
                "card_holder_name": "Card Holder Name",
                "card_number": "Card Number",
                "cvv": "CVV",
                "valid_thru_date": "Card Expiry",
                "mobile_number": "Mobile Number",
                "please_enter_your_parents_information": "Please enter your parent's information",
                "full_name": "Full Name",
                "terms_and_policy": "Terms of Use, Privacy Policy",
                "set_as_default": "Set as default",
                "i_agree_to_all": "I agree to all",
                "next": "Next",
                "save": "Save",
                "my_cards": "My payment cards",
                "default": "default",
                "your_cards": "Your Cards",
                "add": "Add",
                "update": "Update",
                "cancel": "Cancel",
                "delete": "Delete",
                "enter_code": "Enter The Code",
                "resend": "Resend",
                "verify": "Verify",
                "sent_code": "We Have Sent A Code To"
            },
            "education_and_training": {
                "activities": "Main Activities",
                "activities_placeholder": "Describe your Activities",
                "add": "Add",
                "city": "City",
                "country": "Country",
                "education_and_training": "Education and Training",
                "from": "From",
                "from_sweden": "I'm From Sweden",
                "new_education_and_training": "New Education and Training",
                "ongoing": "Ongoing",
                "save": "Save",
                "state": "State",
                "till": "Till"
            },
            "forgot_password": {
                "change_number": "Change Phone Number",
                "enter_code": "Enter The Code",
                "next": "Next",
                "password": "Password",
                "phone_or_email": "Phone Number or Email",
                "please_enter_text": "Please Enter Your",
                "policy_text": "Terms of Use and Privacy Policy",
                "repeat_password": "Repeat Password",
                "resend_code": "Resend Code",
                "sent_code": "We Have Sent A Code To",
                "set_password": "Set Your Password",
                "submit": "Submit",
                "find_your_account": "First, let's find your account",
                "enter_otp": "Enter Otp",
                "enter_password": "Enter Password",
                "sign_in": "Sign In",
                "phone": "Phone"
            },
            "job_application_status": {
                "job_application_status_accepted": "job_application_status_accepted",
                "job_application_status_applied": "job_application_status_applied",
                "job_application_status_dispute ": "",
                "job_application_status_rejected": "job_application_status_rejected",
                "job_application_status_withdrawn": "job_application_status_withdrawn"
            },
            "job_environment_drop": {
                "offline": "At Office",
                "online": "Online",
            },
            "job_filter": {
                "aspirant_type": "Aspirant Type",
                "apply_filter": "Apply Filter",
                "reset_filter": "Reset Filter",
                "applying_date": "Application Date",
                "choose": "Choose",
                "city": "City",
                "country": "Country",
                "distance": "Distance",
                "language": "Language",
                "maximum": "Maximum",
                "minimum": "Minimum",
                "published_date": "Published Date",
                "select_date": "Select Date",
                "skills": "Skills",
                "state": "State",
                "work_type": "Work Type",
                "job_env": "Job Environment",
                "years_of_experience": "Years of Experience",
                "reset": "Reset"
            },
            "job_home": {
                "status": "Status",
                "applicants": "Applicants",
                "applied": "Applied",
                "best_matches": "Best Matches",
                "closing_soon": "Closing Soon",
                "experience": "Experience",
                "favourite": "Favourite",
                "category": "category",
                "sub_category": "sub_category",
                "job_env": "Job Environment",
                "year_of_experience": "Year of Experience",
                "job_title": "Job Title",
                "job_title_postion": "Job Title / Position",
                "application_period": "Application Period",
                "start_date": "Start Date",
                "end_date": "End Date",
                "joining_date": "Joining Date",
                "job_address": "Job Address",
                "duties_and_responsibilities": "Duties and Responsibility",
                "languages": "Languages",
                "jobs": "Jobs",
                "latest": "Latest",
                "my": "My",
                "no_data_found": "No Data Found.",
                "promotion": "Promotions",
                "promotional": "Promotional",
                "random": "Random",
                "recent": "Recent",
                "search_placeholder_sa": "Get yourself a Job",
                "search_placeholder_sp": "Find an Applicant",
                "search_placeholder_view_all": "Search",
                "skills_text": "Skills",
                "nice_to_have_skills": "Nice To Have Skills",
                "vacancies": "Vacancies",
                "view_all": "View All",
                "work_type": "Work Type",
                "year": "Year",
                "years": "Years",
                "details": "Details",
                "my_jobs": "My Jobs",
                "view_applications": "View Applications",
                "resumes": "Resumes",
                "all_applicants": "All Applicants",
                "search_results": "Search Results",
                "hours": "Hours",
                "category": "Category",
                "sub_category": "Sub Category",
                "job_description": "Job Description"
            },
            "job_nature": {
                "1_month": "1 Month",
                "2_months": "2 Months",
                "3_months": "3 Months",
                "4_months": "4 Months",
                "5_months": "5 Months",
                "6_months": "6 Months",
                "7_months": "7 Months",
                "8_months": "8 Months",
                "9_months": "9 Months",
                "10_months": "10 Months",
                "11_months": "11 Months",
                "12_months": "12 Months",
                "13_months": "13 Months",
                "14_months": "14 Months",
                "15_months": "15 Months",
                "16_months": "16 Months",
                "17_months": "17 Months",
                "18_months": "18 Months",
                "19_months": "19 Months",
                "20_months": "20 Months",
                "21_months": "21 Months",
                "22_months": "22 Months",
                "23_months": "23 Months",
                "24_months": "24 Months",
                "25_months": "25 Months",
                "26_months": "26 Months",
                "27_months": "27 Months",
                "28_months": "28 Months",
                "29_months": "29 Months",
                "30_months": "30 Months",
                "31_months": "31 Months",
                "32_months": "32 Months",
                "33_months": "33 Months",
                "34_months": "34 Months",
                "35_months": "35 Months",
                "36_months": "36 Months"
            },
            "job_tabs": {
                "applicants": "Applicants",
                "favourites": "Favourites",
                "jobs": "Jobs",
                "message": "Messages",
                "my_cv": "My CV",
                "my_jobs": "My Jobs",
                "new_vacancy": "New Vacancy"
            },
            "job_type": {
                "contract": "Contract",
                "full_time": "Full Time",
                "internship": "Internship",
                "one_time": "One Time",
                "part_time": "Part Time"
            },
            "languages": {
                "english": "English",
                "swedish": "Swedish"
            },
            "payment_status": {
                "paid": "Paid",
                "failed": "Failed",
                "processing": "Processing",
                "pending": "Pending",
            },
            "messages": {
                "message_invalid_year_dob": "Invalid Date of Birth!",
                "message_rated_successfully": "Rated Successfully",
                "message_valid_rating": "Please give a valid rating",
                "message_enter_valid_reason": "Please enter a valid reason.",
                "message_invalid_username": "Please enter a valid email or phone number",
                "message_boosted_successfully": "Boosted Successfully",
                "message_removed_successfully": "Removed Successfully",
                "message_copied_to_clipboard": "Copied to Clipboard",
                "message_upgrade_package_to_promote": "Upgrade your package to promote this item",
                "message_address_detail_created": "message_address_detail_created",
                "message_address_detail_deleted": "message_address_detail_deleted",
                "message_address_detail_list": "message_address_detail_list",
                "message_address_detail_updated": "message_address_detail_updated",
                "message_alert_title": "Alert",
                "message_already_exist": "message_already_exist",
                "message_basic_detail_updated": "message_basic_detail_updated",
                "message_birthdate_required": "Birthdate Required",
                "message_cancel": "Cancel",
                "message_category_master_created": "Category Master Created Successfully.",
                "message_category_master_deleted": "Category Master Deleted Successfully.",
                "message_category_master_list": "Category Master Retrieved Successfully.",
                "message_category_master_updated": "Category Master Updated Successfully.",
                "message_created": "Created Successfully.",
                "message_submitted": "Submitted Successfully",
                "message_cv_detail_updated": "CV Detail Updated Successfully.",
                "message_deleted": "Deleted Successfully.",
                "message_dublicate_entry": "Dublicate Entry.",
                "message_education_detail_created": "Education Detail Created Successfully.",
                "message_education_detail_deleted": "Education Detail Deleted Successfully.",
                "message_education_detail_list": "Education Detail Retrieved Successfully.",
                "message_education_detail_updated": "Education Detail Updated Successfully.",
                "message_email_required": "Email Required",
                "message_error": "Oops!! Something went wrong. Please try again later.",
                "message_error_title": "Error",
                "message_extra_detail_updated": "message_extra_detail_updated",
                "message_faq_created": "Faq Created Successfully.",
                "message_faq_deleted": "Faq Deleted Successfully.",
                "message_faq_list": "Faq Retrieved Successfully.",
                "message_faq_updated": "Faq Updated Successfully.",
                "message_fill_all_details": "Fill All Details",
                "message_first_name_required": "First Name Required",
                "message_g_contact_number_required": "Gaurdian Mobile Number Required",
                "message_g_email_required": "Gaurdian Email Required",
                "message_g_first_name_required": "Gaurdian First Name Required",
                "message_g_last_name_required": "Gaurdian Last Name Required",
                "message_gender_required": "Gender Required",
                "message_institute_name": "Name of the institute.",
                "message_internet_required": "Please check your internet connection and try again.",
                "message_invalid_contact_number": "Please enter a valid Mobile Number.",
                "message_invalid_email": "Please enter a valid Email.",
                "message_invalid_otp": "Please enter a valid otp",
                "message_job_application_created": "message_job_application_created",
                "message_job_application_deleted": "message_job_application_deleted",
                "message_job_application_list": "message_job_application_list",
                "message_job_application_updated": "message_job_application_updated",
                "message_label_created": "Label Created Successfully.",
                "message_label_deleted": "Label Deleted Successfully.",
                "message_label_group_created": "Label Group Created Successfully.",
                "message_label_group_deleted": "Label Group Deleted Successfully.",
                "message_label_group_list": "Label-Groups Retrived Successfully.",
                "message_label_group_updated": "Label Group Updated Successfully.",
                "message_label_list": "Labels Retrived Successfully.",
                "message_label_updated": "Label Updated Successfully.",
                "message_language_created": "Language Created Successfully.",
                "message_language_deleted": "Language Deleted Successfully.",
                "message_language_list": "Languages Retrived Successfully.",
                "message_language_updated": "Language Updated Successfully.",
                "message_last_name_required": "Last Name Required",
                "message_module_type_created": "Module Type Created Successfully.",
                "message_module_type_deleted": "Module Type Deleted Successfully.",
                "message_module_type_list": "Module Type Retrieved Successfully.",
                "message_module_type_updated": "Module Type Updated Successfully.",
                "message_no": "No",
                "message_ok": "Ok",
                "message_old_password_error": "message_old_password_error",
                "message_otp_sent": "One time otp password has been sent to your entered contact number",
                "message_page_created": "Page Created Successfully.",
                "message_page_deleted": "Page Deleted Successfully.",
                "message_page_list": "Page Retrieved Successfully.",
                "message_page_updated": "Page Updated Successfully.",
                "message_password_length": "Password length should be atleast 6 characters long.",
                "message_password_mismatch": "Password and repeat password must be identical.",
                "message_password_updated": "message_password_updated",
                "message_reward_point_setting_created": "Reward Point Setting Created Successfully.",
                "message_reward_point_setting_deleted": "Reward Point Setting Deleted Successfully.",
                "message_reward_point_setting_list": "Reward Point Setting Retrieved Successfully.",
                "message_reward_point_setting_updated": "Reward Point Setting Updated Successfully.",
                "message_service_provider_type_created": "Service Provider Type Created Successfully.",
                "message_service_provider_type_deleted": "Service Provider Type Deleted Successfully.",
                "message_service_provider_type_list": "Service Provider Type Retrieved Successfully.",
                "message_service_provider_type_updated": "Service Provider Type Updated Successfully.",
                "message_something_went_wrong": "Something went wrong, please try again.",
                "message_ssn_required": "SSN Required",
                "message_student_card": "Photo of provided Student/Enrollment ID card.",
                "message_student_number": "Student/Enrollment number provided by institute.",
                "message_success_title": "Success",
                "message_updated": "Updated Successfully.",
                "message_user_created": "User Created Successfully.",
                "message_user_deleted": "User Deleted Successfully.",
                "message_user_detail_saved": "User detail saved Successfully",
                "message_user_list": "Users Retrived Successfully",
                "message_user_login_credencial_error": "Credencial not valid.",
                "message_user_login_success": "User logged in successfully.",
                "message_user_not_exists": "User does not exists.",
                "message_user_registered": "User Registered Successfully",
                "message_user_type_created": "User Type Created Successfully.",
                "message_user_type_deleted": "User Type Deleted Successfully.",
                "message_user_type_list": "User Type Retrieved Successfully.",
                "message_user_type_updated": "User Type Updated Successfully.",
                "message_user_updated": "User Updated Successfully.",
                "message_validation": "Error While Validating Data",
                "message_work_experience_created": "Work Experience Created Successfully.",
                "message_work_experience_deleted": "Work Experience Deleted Successfully.",
                "message_work_experience_list": "Work Experience Retrieved Successfully.",
                "message_work_experience_updated": "Work Experience Updated Successfully.",
                "message_years_of_study": "Number of years you have studied in the institute.",
                "message_yes": "Yes",
                "message_favourite_job_created": "message_favourite_job_created",
                "message_favourite_job_deleted": "message_favourite_job_deleted",
                "message_favourite_job_list": "message_favourite_job_list",
                "message_job_promote": "message_job_promote",
                "message_job_publish": "message_job_publish",
                "message_job_tags_list": "message_job_tags_list",
                "message_job_update-status": "status",
                "message_label_group_info": "message_label_group_info",
                "message_language_changed": "message_language_changed",
                "message_language_list": "message_language_list",
                "message_company_name_required": "Company Name Required",
                "message_org_number_required": "Organization Number Required",
                "message_vat_number_required": "VAT Registration Number Required",
                "message_vat_file_required": "VAT Registration File Required",
                "message_reg_type_required": "Registration Type Required",
                "message_sp_type_required": "Service Provider Type Required",
                "message_establishment_year_required": "Year of Establishment Required",
                "message_completion_year_required": "Year of Completion Required",
                "message_company_name": "Registered Name of the company",
                "message_year_of_establishment": "Year of establishment eg:2020",
                "message_completion_year": "Year of Completion eg:2020, not less than 3 years from current year.",
                "message_vat_reg_file": "VAT registration document in pdf format.",
                "message_vat_reg_number": "VAT registration number.",
                "message_org_number": "Organization number",
                "message_invalid_year": "Please enter a valid year.",
                "message_file_size_error": "File size too large",
                "message_invalid_year_founded": "Year of establishment can not exceed current year",
                "message_contact_number_required": "Mobile number required",
                "message_address_required": "Address required",
                "message_invalid_org_number": "Please enter a valid organization number",
                "message_invalid_vat_number": "Please enter a valid VAT Registration number",
                "message_same_number": "Same as company number",
                "message_same_email": "Same as company email",
                "message_about_company_required": "About company required",
                "message_website_url_required": "Website url required",
                "message_logo_required": "Company logo image required",
                "message_download_success": "Downloaded Successfully",
                "message_unable_to_downlaod": "Unable to download, try again",
                "message_want_to_delete": "Do you want to delete this ?",
                "message_job_posted": "Job Posted Successfully",
                "message_job_updated": "Job Updated Successfully",
                "message_job_publish": "Publish job to make it visible to applicants, but published job can not be edited, so you can publish it later as well",
                "message_job_hour_required": "Job hours required",
                "message_duration_required": "Duration required",
                "message_nice_to_have_skills_required": "Nice to have skills required",
                "message_select_start_date": "Please select start date",
                "message_select_end_date": "Please select end date",
                "message_added_to_fav": "Added to favourites",
                "message_removed_from_fav": "Removed from favourites",
                "message_not_an_student": "You can not be considered as student",
                "message_invalid_student_number": "Please enter a valid student number",
                "message_select_category": "Please select category",
                "message_select_subcategory": "Please select subcategory",
                "message_select_brand": "Please select brand",
                "message_product_title_required": "Please enter product name",
                "message_gtin_required": "Please enter GTIN",
                "message_sku_required": "Please enter SKU",
                "message_sku_info": "Stock Keeping Unit Details",
                "message_quantity_required": "Please enter valid quantity",
                "message_price_required": "Please enter valid price",
                "message_discount_percent_required": "Please enter valid discount percent",
                "message_product_location_required": "Please select product location",
                "message_delivery_type_required": "Please select delivery type",
                "message_product_description_required": "Please enter description",
                "message_delivery_date_required": "Please select delivery date",
                "message_image_required": "Please add atleast one image",
                "message_max_image_selected": "Maximum 6 images can be selected",
                "message_quality_required": "Please enter valid quality",
                "message_service_title_required": "Please enter Service name",
                "message_duration_required": "Please enter valid duration",
                "message_languages_required": "Please select atleast one language",
                "message_service_type_required": "Please select service type",
                "message_service_location_required": "Please select service location",
                "message_book_title_required": "Please enter book name",
                "message_isbn_required": "Please enter ISBN",
                "message_invalid_isbn": "Please enter a valid ISBN",
                "message_author_required": "Please enter author",
                "message_published_year_required": "Please enter published year",
                "message_publisher_required": "Please enter publisher",
                "message_language_required": "Please select a language",
                "message_pages_required": "Please enter valig number of pages",
                "message_suitable_age_required": "Please select suitable age",
                "message_book_cover_required": "Please select book cover",
                "message_dimensions_required": "Please enter valid dimensions",
                "message_weight_required": "Please enter valid weight",
                "message_book_location_required": "Please select book location",
                "message_deposit_required": "Please enter valid deposit",
                "message_cover_image_required": "Please choose a cover image",
                "message_promoted_successfully": "Promotion added Successfully",
                "message_published_successfully": "Published Successfully",
                "message_unpromoted_successfully": "Promotion Removed Successfully",
                "message_unpublished_successfully": "Unpublished Successfully",
                "message_package_updated_successfully": "Packages Upgraded Successfully",
                "message_item_successfully_added_to_cart": "Item successfully added to cart",
                "message_increase_quantity_to_item": "Increase quantity to add item",
                "message_quantity_updated": "Successfully updated quantity",
                "message_deletion_confirmation": "Do you want to delete this ?",
                "message_empty_cart_success": "Cart Cleared Successfully",
                "message_order_placed_successfully": "Your order has been placed successfully",
                "message_please_accept_terms_condition": "Please first accept terms and condition to place order.",
                "message_add_max_images": "You can only add up to max four images",
                "message_please_choose_reason": "Choose reason for the return",
                "message_please_choose_tracking_number": "Enter a valid tracking number",
                "message_please_choose_shipping_company": "Choose shipping company first",
                "message_select_atleast_one_image": "Select atleast one image",
                "message_expected_return_date": "Select expected return date first"
            },
            "my_cv": {
                "total_exp": "Total Year of Experience",
                "employer": "Employer",
                "name": "Title",
                "address": "Address",
                "complete_cv": "Complete Your CV",
                "download_cv": "Download CV",
                "download_resume": "Download Resume",
                "education_and_training": "Education and Training",
                "email": "Email",
                "female": "Female",
                "first_name": "First Name",
                "gender": "Gender",
                "job_env": "Job Environment",
                "language": "Languages",
                "last_name": "Last Name",
                "male": "Male",
                "mobile_number": "Mobile Number",
                "others": "Others",
                "personal_info": "Personal Information",
                "present": "Present",
                "skills": "Skills",
                "title": "Title",
                "update_cv": "Update Your CV",
                "upload_resume": "Upload Resume",
                "work_exp": "Work Experience",
                "years": "Years",
                "contact_number": "Contact Number",
                "dob": "Date of Birth",
                "country": "Country",
                "city": "City",
                "main_activities": "Main Activities",
                "my_cv": "My CV",
                "personal_information": "Personal Information",
                "submit": "Submit",
                "are_you_from_sweden_?": "Are you From Sweden ?",
                "country_name": "Country Name",
                "from": "From",
                "to": "To",
                "currently_working": "Currently Working"
            },
            "my_info": {
                "change_password": "Change Password",
                "contact_support": "Contact Support",
                "edit_info": "Edit Your Information",
                "my_addresses": "My Addresses",
                "my_cards": "My Cards",
                "sa_title": "My Information",
                "save": "Save Changes",
                "sp_title": "Store Information"
            },
            "my_profile": {
                "active_account": "Your account is Approved",
                "inactive_account": "Your account is Pending Verification",
                "my_info": "My Information",
                "my_marketplace": "My Market Place",
                "my_orders": "My Orders",
                "points": "Points",
                "printing_and_packaging": "Printing And Packaging",
                "you_have": "You have",
                "request": "Requests",
                "management": "Management"
            },
            "register": {
                "qr_code_img_path": "QR",
                "product_package": "Product Package",
                "service_package": "Service Package",
                "book_package": "Book Package",
                "contest_package": "Contest Package",
                "job_package": "Job Package",
                "package_information": "Package Information",
                "upgrade_package": "Upgrade Package",
                "bank_details": "Bank Details",
                "bank_account_type": "Bank Account Type",
                "bank_name": "Bank Name",
                "bank_account_num": "Bank Account Number",
                "bank_identifier_code": "Bank Identifier Code",
                "address": "Address",
                "pincode": "Postal Code",
                "address_title": "Address Title (Home. Office, ...)",
                "agree_text": "I agree to all",
                "back": "Back",
                "birth_date": "Date of Birth",
                "change_number": "Change Phone Number",
                "company_name": "Company Name",
                "easy_text": "Easy & Quick",
                "educational_institute": "Educational Institute",
                "educational_level": "Educational Level",
                "email": "Email",
                "enter_code": "Enter The Code",
                "enter_phone_number": "Please Enter Your Phone Number",
                "female": "Female",
                "fill_education_info": "Fill Your Education Information",
                "fill_work_info": "Fill Your Work Information",
                "final_step": "Final Step",
                "finish": "Finish",
                "first_name": "First Name",
                "last_name": "Last Name",
                "student_first_name": "Student First Name",
                "student_last_name": "Student Last Name",
                "male": "Male",
                "mobile_number": "Mobile Number",
                "name_of_institute": "Name of the Institute",
                "next": "Next",
                "no_of_years_of_study": "Number of years of Study",
                "password": "Password",
                "personal_info": "Personal Information",
                "policy_text": "Terms of Use and Privacy Policy",
                "registration_number": "Commercial Registration No.",
                "registration_type": "Registration Type",
                "repeat_password": "Repeat Password",
                "resend_code": "Resend Code",
                "sent_code": "We Have Sent A Code To",
                "service_provider_type": "Service Provider Type",
                "set_password": "Set Your Password",
                "student_card": "Student Card",
                "student_number": "Student Number",
                "terms_and_policy": "Terms of Use, Privacy Policy",
                "title_service_provider": "Register New Service Provider",
                "title_student": "Register New Student",
                "vat_registration": "VAT Registration Number",
                "verify": "Verify",
                "year_founded": "Year of Establishment",
                "register": "Register",
                "register_new": "Register New",
                "new_student": "New Student",
                "service_provider": "Service Provider",
                "social_security_number": "Social Security Number",
                "completion_year": "Year of Completion",
                "fill_guardian_info": "Fill Guardian Info",
                "g_first_name": "Gaurdian First Name",
                "g_last_name": "Gaurdian Last Name",
                "g_email": "Gaurdian Email",
                "g_password": "Gaurdian Password",
                "g_password_confirm": "Confirm Gaurdian Password",
                "g_contact_number": "Gaurdian Contact Number",
                "email_otp": "Enter Email OTP",
                "mobile_otp": "Enter Mobile OTP",
                "change_email_and_phone": "Change Email and Phone Number",
                "about_service_provider": "About Service Provider",
                "contact_person_info": "Contact Person Details",
                "service_provider_info": "Service Provider Details",
                "edu_info": "Education Information",
                "vat_registration_file": "Vat Registration.pdf",
                "organization_number": "Organization Number",
                "subscription_package_info": "Subscription Packages",
                "about_service_provider": "About Service Provider",
                "about_company": "About Company",
                "website_url": "Website URL",
                "logo_image": "Logo Image",
                "download": "Download",
                "short_intro_placeholder": "Write something about your self",
                "short_intro": "Short Intro",
                "gender": "Gender",
                "view": "View",
                "years": "Years",
                "policy_text": "By clicking Register, You agree to the Student Store ",
                "profile_pic": "Profile Picture",
                "title_buyer": "Buyer Registration"
            },
            "packages": {
                "number_of_contest": "Number of Contests",
                "number_of_event": "Number of Events",
                "yes": "Yes",
                "number_of_contest_event": "Number of Contest / Events",
                "cancel": "Cancel Subscription",
                "job_packages": "Job Packages",
                "product_packages": "Product Packages",
                "service_packages": "Service Packages",
                "contest_packages": "Contest Packages",
                "book_packages": "Book Packages",
                "packages_type_of_package": "Type of package",
                "packages_job_ads": "Job ads",
                "packages_publications_day": "Publication days",
                "packages_duration": "Duration",
                "packages_cvs_view": "CV’s view",
                "packages_boost": "Boost",
                "packages_employees_per_job_ad": "Employees per job ad",
                "packages_no_of_boost": "No. of boost",
                "packages_boost_no_of_days": "Boost no. of days",
                "packages_price": "Price",
                "packages_start_up_fee": "Start up Fee",
                "packages_subscription": "Subscription",
                "packages_no_of_product_services": "No of product / Service",
                "packages_commission": "Commission",
                "packages_commission_per_sale": "Commission per sale",
                "packages_sponsar_cost": "Sponsor cost",
                "packages_number_of_product": "No. of Products",
                "packages_number_of_service": "No. of Services",
                "packages_number_of_book": "No. of Books",
                "packages_notice_month": "Notice Period",
                "packages_locations": "Locations",
                "packages_range_of_age": "Range of Age",
                "packages_range_of_age_from": "Range of Age From",
                "packages_range_of_age_to": "Range of Age To",
                "packages_organization": "Organization",
                "packages_attendees": "Attendees",
                "packages_cost_for_each_attendee": "Cost for Each attendee",
                "packages_top_up_fee": "Top up fee",
                "packages_free": "Free",
                "packages_basic": "Basic",
                "packages_standard": "Standard",
                "packages_premium": "Premium",
                "subscription_packages": "Subscription Packages",
                "package_detail": "Package Detail",
                "package_valid_till": "Valid Till",
                "register": "Register",
                "job": "Job",
                "products": "Products",
                "books": "Books",
                "contest": "Contest",
                "service": "Service",
                "upgrade_subscription": "Upgrade Packages",
                "months": "Months",
                "days": "Days",
                "module": "Module",
                "duration_of_the_package": "Duration of the package",
                "number_of_job_adds_you_can_post": "Number of job ads you can post",
                "start_up_fee_of_the_package": "Start up fee of the package",
                "job_adds_you_can_post_per_employee": "Job adds you can post per employee",
                "employee_per_job_ad": "Employees per job ad",
                "total_cv_views": "Total cv views",
                "promotion": "Promotion",
                "number_of_jobs_you_can_promote": "Number of jobs you can promote",
                "number_of_days_you_can_promote_jobs": "Number of days you can promote jobs",
                "promotion_no_of_days": "Promotion no. of days",
                "number_of_products_you_can_promote": "Number of products you can promote",
                "number_of_services_you_can_promote": "Number of services you can promote",
                "number_of_days_you_can_promote_product": "Number of days you can promote product",
                "number_of_days_you_can_promote_service": "Number of days you can promote service",
                "number_of_books_you_can_promote": "Number of books you can promote",
                "number_of_days_you_can_promote_book": "Number of days you can promote book",
                "notice_period_of_package": "Notice period of package",
                "total_commission_per_sale": "Total commission per sale",
                "total_number_of_products": "Total number of products",
                "number_of_books_you_can_promote": "Number of books you can promote",
                "number_of_days_you_can_promote_books": "Number of days you can promote books",
                "total_cost_of_each_attendee": "Total cost of each attendee",
                "organization": "Organization",
                "total_top_up_fee": "Total top up fee",
                "total_attendee": "Total attendee",
                "location": "Location",
                "unlimited": "Unlimited",
                "buy_now": "Buy now",
                "validity": "Valid till",
                "jobs_adds": "Jobs adds",
                "cv_views": "CV views",
                "promotion_count": "Promotion",
                "most_popular": "Most popular",
                "top_selling": "Top selling",
                "total_number_of_services": "Total number of services",
                "selected": "Selected",
                "cancel": "Cancel",
                "select": "Select",
                "update": "Update Package",
                "add_payment_card": "Add New Card",
                "please_add_a_card": "No payment method found. please add a card",
                "current": "Current",
                "package_product_detail": "Current Product Package Details",
                "package_service_detail": "Current Service Package Details",
                "package_book_detail": "Current Book Package Details",
                "package_job_detail": "Current Job Package Details",
                "package_contest_detail": "Current Contest Package Details",
            },
            "my_favourites": {
                "products": "Products",
                "services": "Services",
                "jobs": "Jobs",
                "books": "Books",
                "applicants": "Applicants",
                "data_not_found": "No Data Found"
            },
            "image_picker_texts": {
                "select_photo": "Select Photo",
                "launch_camera": "Launch Camera",
                "load_from_gallery": "Load from Gallery",
                "cancel": "Cancel"
            },
            "registration_type": {
                "type_1": "Registration Type 1",
                "type_2": "Registration Type 2",
                "type_3": "Registration Type 3",
                "type_4": "Registration Type 4"
            },
            "service_provider_type": {
                "type_1": "Service Provider 1",
                "type_2": "Service Provider 2",
                "type_3": "Service Provider 3",
                "type_4": "Service Provider 4"
            },
            "side_menu": {
                "contact_us": "Contact Us",
                "copyright_text": "Copyright 2021 @ Student Store",
                "logout": "Logout",
                "messages": "Messages",
                "my_favourite": "My Favourite",
                "my_marketplace": "My Market Place",
                "my_orders": "My Orders",
                "my_profile": "My Profile",
                "notifications": "Notifications",
                "packages": "Packages",
                "reward_points": "Reward Points",
                "printing_and_packagin": "Printing And Packaging",
                "seller": "Seller",
                "buyer": "Buyer"
            },
            "work_experience": {
                "activities_and_responsibilities": "Main Activities and Responsibilities",
                "activities_placeholder": "Describe your tasks and responsibilities",
                "add": "Add",
                "city": "City",
                "country": "Country",
                "employer": "Employer",
                "from": "From",
                "from_sweden": "I'm From Sweden",
                "new_work_exp": "New Work Experience",
                "save": "Save",
                "state": "State",
                "till": "Till",
                "title": "Title",
                "work_exp": "Work Experience",
                "working": "Working"
            },
            "notifications": {
                "title": "Notifications",
                "no_data_found": "No Data Found",
                "clear": "Clear",
                "no_data": "No Notifications"
            },
            "delivery_type": {
                "deliver_to_location": "To Address",
                "pickup_from_location": "Take Away"
            },
            "duration_type": {
                "hours": "Hours",
                "days": "Days",
                "weeks": "Weeks",
                "months": "Months"
            },
            "book_cover": {
                "title": "Book Cover"
            },
            "service_type": {
                "online": "Online",
                "at_customer": "At Customer Location",
                "at_center": "At Center"
            },
            "sell_type": {
                "free": "Free",
                "for_rent": "For Rent",
                "for_sale": "For Sale"
            },
            "suitable_age": {
                "5-9": "5 to 9 years",
                "9-13": "9 to 13 years",
                "13-18": "13 to 18 years",
                "18+": "18 years and above",
                "all": "All"
            },
            "add_product_service_book": {
                "mark_as_sold": "Mark as sold",
                "service_provider_type": "Service Provider Type",
                "select_service_provider_type": "Select",
                "add_product": "Add Product",
                "add_service": "Add Service",
                "add_book": "Add Book",
                "seo": "SEO",
                "shipping_charges": "Shipping Charge",
                "max_points": "Max reward points:",
                "rewards": "Rewards",
                "mark_sold": "Mark as Sold",
                "marked_sold": "Marked as Sold",
                "edit": "Edit",
                "product": "Products",
                "service": "Services",
                "book": "Books",
                "categories": "Categories",
                "subCategories": "Sub Categories",
                "select": "Select",
                "brand": "Brand",
                "product_title": "Product Name",
                "sku": "SKU",
                "gtin": "GTIN",
                "service_title": "Service Name",
                "book_title": "Book Name",
                "quantity": "Quantity",
                "price": "Original Price",
                "apply_discount": "Apply Discount",
                "discount_value": "Discount In Percent",
                "discount_placeholder": "eg:10",
                "discounted_price": "Price after discount",
                "product_location": "Product Location",
                "service_location": "Service Location",
                "service_type": "Service Type",
                "book_location": "Book Location",
                "delivery_type": "Delivery Type",
                "description": "Description",
                "delivery_date": "Delivery Date",
                "images": "Images",
                "tap_to_make_cover": "Tap on image to set as cover image",
                "product_tags": "Tags",
                "boost": "Boost product",
                "promote": "Promote",
                "next": "Next",
                "back": "Back",
                "cancel": "Cancel",
                "submit": "Submit",
                "add_more": "Add More",
                "go_to_my_products": "Go To My Products",
                "quality": "Quality (%)",
                "duration": "Duration",
                "dimension": "Dimensions",
                "width": "Width",
                "height": "Height",
                "length": "Length",
                "weight": "Weight",
                "in_cm": "in cm",
                "in_gram": "in grams",
                "book_rent_price": "Rent Price Per Day",
                "free": "Free",
                "for_rent": "For Rent",
                "for_sale": "For Sale",
                "languages": "Languages",
                "isbn": "ISBN",
                "author": "Author",
                "published_year": "Published Year",
                "publisher": "Publisher",
                "pages": "Pages",
                "suitable_age": "Suitable Age",
                "language": "Language",
                "book_cover": "Book Cover",
                "deposit": "Deposit",
                "publish": "Publish",
                "sell_type": "Sell Type",
                "service_online_link": "Service Online Link",
                "used_product": "Used Product",
                "price_range": "Price Range",
                "min_price": "Min Price",
                "max_price": "Max Price",
                "city": "City",
                "cities": "Cities",
                "apply_filter": "Apply",
                "delete": "Delete",
                "enter_product_title": "Enter Product Title Here",
                "upload_files_here": "Upload Images",
                "select_files": "Select Files",
                "click_to_remove": "Click To Remove",
                "click_to_select_cover": "Click To Select Cover",
                "service_link": "Service Link (URL)",
                "service_time": "",
                "off": "Off",
                "meta_title": "Meta Title",
                "meta_keywords": "Meta Keywords",
                "meta_description": "Meta Description",
            },
            "product_detail_screen": {
                "quantity": "Quantity",
                "shipping": "Shipping",
                "location": "Location",
                "product_description": "Product Description",
                "customer_reviews": "Customer Reviews",
                "similar_products": "Similar Products",
                "view_all": "View all",
                "store": "Store",
                "ask": "Ask",
                "add_to_cart": "Add To Cart",
                "buy_now": "Buy Now",
                "view_on_map": "View On Map",
                "zoom": "Zoom",
                "seller": "Seller",
                "edit": "Edit",
                "boost": "Boost",
                "boost_details": "Boost Details",
                "upgrade_package": "Upgrade Package",
                "promotion": "Promotion",
                "top_selling": "Top Selling",
                "most_popular": "Most Popular",
                "end_on": "Will End on",
                "view_in_cart": "View In Cart",
                "similar_services": "Similar Services",
                "no_data_found": "Data Not Found."
            },
            "my_market_place": {
                "my_market_place": "My Market Place",
                "add_a_new_item": "Add A New Item",
                "use_gtin_or_isbn": "Use GTIN Or ISBN",
                "you_can_choose_to_add_it_manually": "you can choose to add it manually",
                "product_for_sale": "Product For Sale",
                "Services_to_others": "Service to others",
                "books": "Books"
            },
            "product_view_all": {
                "all": "All",
                "promotions": "Promotions",
                "seller": "Seller",
                "search_result": "Search Results",
                "no_data_found": "No Data Found"
            },
            "add_to_cart": {
                "delivery_type": "Delivery Type :",
                "to_address": "To Address",
                "take_away": "Take Away",
                "add_new_address": "Add New Address",
                "next": "Next",
                "note": "Note :",
                "back": "Back",
                "add_to_cart": "Add To Cart",
                "successfully_added": "Successfully Added",
                "seller": "Seller :",
                "total": "Total :",
                "points": "Points",
                "go_to_cart": "Go To Cart",
                "continue_shopping": "Continue Shopping",
                "default": "default",
                "delivering_to": "Delivering to :"
            },
            "review_and_rating": {
                "welcome_text": "Your opinion matters to us",
                "product": "Product",
                "service": "Service",
                "book": "Book",
                "submit": "Submit",
                "seller": "Seller",
                "review_placeholder": "Write Something"
            },
            "promotion_modal": {
                "delivery_type": "Delivery Type :",
                "to_address": "To Address",
                "take_away": "Take Away",
                "add_new_address": "Add New Address",
                "next": "Next",
                "note": "Note",
                "tracking_number": "Tracking Number",
                "reason_placeholder": "Write Something",
                "reason": "Reason",
                "expected_delivery_date": "Expected Delivery Date",
                "tracking_number_placeholder": "Tracking number of the item",
                "back": "Back",
                "cancel": "Cancel",
                "done": "Done",
                "add_to_cart": "Add To Cart",
                "successfully_added": "Successfully Added",
                "seller": "Seller",
                "total": "Total",
                "points": "Points",
                "go_to_cart": "Go To Cart",
                "continue_shopping": "Continue Shopping",
                "default": "default",
                "delivering_to": "Delivering to",
                "top_selling": "Top Selling",
                "most_popular": "Most Popular",
                "promotion": "Promotion"
            },
            "product_place_order_screen": {
                "seller": "Seller",
                "add_new_address": "Add New Address",
                "default": "default",
                "take_away": "Take Away",
                "to_address": "To Address",
                "delivery_type": "Delivery Type :",
                "next": "Next",
                "sub_total": "Sub-total :",
                "shipping": "Shipping :",
                "tax": "Tax :",
                "total": "Total :",
                "payment_method": "Payment method",
                "delivery_address": "Delivery Address",
                "use_my_reward_points": "Use my reward points",
                "new_card": "New card",
                "you_can_add_notes_to_your_order": "you can add notes to your order",
                "i_agree_to_all": "By clicking checkout, you agree to our",
                "terms_of_use_and_privacy_policy": "Terms of Use and Privacy Policy",
                "check_out": "Check Out",
                "cancel": "Cancel",
                "my_address": "My Address",
                "pick_up_from_location": "Pickup from location :",
                "deliver_to_location": "Deliver to location",
                "online": "Online",
                "vat": "VAT"
            },
            "product_order_successfully_placed_screen": {
                "well_done": "Well Done",
                "your_order_no": "Your Order No. ",
                "was_submitted_successfully": " Was Submitted Successfully",
                "seller": "Seller :",
                "total": "Total :",
                "points": "Points",
                "delivering_to": "Delivering to :",
                "continue_shopping": "Continue Shopping",
                "go_to_my_orders": "Go To My Orders"
            },
            "used_product_screen": {
                "recently_added_products": "Recently added",
                "view_all": "View all",
                "random_products": "Random",
                "promotions": "Promotions",
                "inside_all_text": "It's all inside... search",
                "products": "Products",
                "services": "Services",
                "best_selling_product": "Best selling",
                "data_not_found": "No Data Found.",
                "most_popular_services": "Most popular",
                "top_rated": "Top rated",
                "latest": "Latest"
            },
            "my_market_place_bar_code_scanner": {
                "add_a_new_item": "Add A New Item",
                "scan_or_enter": "Scan Or Enter",
                "gtin_or_isbn_barcode": "GTIN Or ISBN Barcode",
                "scan": "Scan",
                "gtin_description": "The Global Trade Item Number is an identifier for trade items, developed by GS1. Such identifiers are used to look up product ... information in a database which may belong to a retailer, manufacturer, collector, researcher, or other entity.",
                "i_dont_have_barcode": "I don't have barcode"
            },
            "product_my_orders": {
                "my_orders": "My Orders",
                "product": "Products",
                "search_bar_text_": "Search....",
                "service": "Services",
                "book": "Books",
                "hide_completed_orders": "Hide Completed Orders",
                "order_no": "Order No. ",
                "delivering_to": "Delivering to :",
                "total": "Total :",
                "points": "Points",
                "rate": "Rate",
                "go_and_pickup": "Go and pickup",
                "completed": "Completed",
                "under_process": "Under Process",
                "canceled": "Canceled",
                "shipped": "Shipped",
                "quantity": "Quantity",
                "return": "Return",
                "no_data_found": "No Data Found",
                "tracking_number": "Tracking No.",
                "expected_delivery_date": "Exp. Delivery Date",
                "seller": "Seller : "
            },
            "product_my_cart": {
                "seller": "Seller :",
                "delivering_to": "Delivering to :",
                "sub_total": "Sub-total :",
                "shipping": "Shipping :",
                "tax": "Tax :",
                "total": "Total :",
                "payment_method": "Payment method :",
                "use_my_reward_points": "Use my reward points",
                "new_card": "New card",
                "new_address": "New Address",
                "you_can_add_notes_to_your_order": "you can add notes to your order",
                "i_agree_to_all": "By clicking checkout, you agree to our",
                "terms_of_use_and_privacy_policy": "Terms of Use and Privacy Policy",
                "check_out": "Check Out",
                "cancel": "Cancel",
                "continue_shopping": "Continue Shopping",
                "empty_cart": "Empty Cart",
                "select_address": "Select Address",
                "vat": "VAT",
                "delivery_address": "Delivery address :"
            },
            "product_myorders_detail": {
                "order_number": "Order No.",
                "seller": "Seller",
                "address_type": "Address type :",
                "confirm_receipt": "Confirm Receipt",
                "contact_the_seller": "Contact Seller",
                "cancel_item": "Cancel Item",
                "total": "Total",
                "points": "Points",
                "quantity": "Quantity",
                "payment_card": "Payment Card",
                "rate": "Rate",
                "go_and_pickup": "Go and pickup",
                "tracking_number": "Tracking No.",
                "return_item": "Return Item",
                "expected_delivery_date": "Exp. Delivery Date",
                "seller_with_colon": "Seller : "
            },
            "manage_my_market_place": {
                "manage_my_market_place": "Manage market place",
                "well_done": "Well Done",
                "your_order_no": "Your Order No. ",
                "was_submitted_successfully": " Was Submitted Successfully",
                "seller": "Seller :",
                "total": "Total :",
                "points": "Points",
                "delivering_to": "Delivering to :",
                "continue_shopping": "Continue Shopping",
                "go_to_my_orders": "Go To My Orders",
                "product_location": "Product location",
                "scan": "Scan",
                "product": "Products",
                "service": "Services",
                "book": "Books",
                "search_bar_text_": "Search....GTIN-SKU-ISBN-Word",
                "scan": "Scan",
                "no_data_found": "No Data Found",
                "price": "Price",
                "pending_for_approval": "Pending for approval",
                "approved_but_not_published": "Approved but not published",
                "approved_and_published": "Approved and published",
                "rejected": "Rejected",
                "data_not_found": "No Data Found.",
                "my_listings": "My Listings",
                "my_orders": "My Orders",
                "statistics": "Statistics"
            },
            "order_status": {
                "all": "All",
                'joined': "Joined",
                "rejected": "Rejected",
                "completed": "Completed",
                "pending": "Pending",
                "processing": "Processing",
                "shipped": "Shipped",
                "delivered": "Delivered",
                "completed": "Completed",
                "canceled": "Canceled",
                "return_initiated": "Return Initiated",
                "returned": "Returned",
                "replacement_initiated": "Replacement Initiated",
                "replaced": "Replaced",
                "declined": "Declined",
                "dispute_initiated": "Dispute",
                "reviewed_by_seller": "Reviewed"
            },
            "order_status_custom": {
                "all": "All",
                "pending": "Pending",
                "processing": "Processing",
                "shipped": "Shipped",
                "delivered": "Delivered",
                "completed": "Completed",
                "canceled": "Canceled",
                "return_initiated": "Return Initiated",
                "returned": "Returned",
                "replacement_initiated": "Replacement Initiated",
                "replaced": "Replaced",
                "declined": "Declined",
                "dispute_initiated": "Dispute",
                "cancellation_request_accepted": "Canceled",
                "ask_for_cancellation": "Asked for Cancellation",
                "ask_to_cancel": "Cancel",
                "cancel": "Cancel",
                "cancelation_request_accepted": "Cancelled",
                "reviewed_by_seller": "Reviewed",
                "payment_paid": "Payment Success",
                "payment_failed": "Payment Failed",
                "payment_processing": "Payment Processing",
                "ask_to_canceled": "Asked for cancellation"
            },
            "dispute_status": {
                "declined": "Declined",
                "dispute_initiated": "Dispute Initiated",
                "resolved_to_customer": "Resolved to Customer",
                "resolved_to_seller": "Resolved to Seller",
                "reviewed_by_seller": "Reviewed",
                "review_accepted": "Review Accepted"
            },
            "my_market_place_requests": {
                "tracking_number": "Tracking No.",
                "expected_delivery_date": "Exp. Delivery Date",
                "market_place_requests": "Market place Requests",
                "my_orders": "Orders",
                "product": "Products",
                "service": "Services",
                "book": "Books",
                "hide_completed_orders": "Hide Completed Orders",
                "order_no": "Order No. ",
                "delivering_to": "Delivering to :",
                "total": "Total :",
                "points": "Points",
                "rate": "Rate",
                "go_and_pickup": "Go and pickup",
                "completed": "Completed",
                "under_process": "Processing",
                "canceled": "Canceled",
                "pending": "Pending",
                "shipped": "Shipped",
                "ship": "Ship",
                "process": "Process",
                "deliver": "Deliver",
                "return": "Return",
                "accept_return": "Accept Return",
                "no_data_found": "No Data Found"
            },
            "store_screen": {
                "business_license": "Business License",
                "store": "store",
                "about": "About",
                "products_and_services": "Products and services",
                "view_all": "View all",
                "customer_reviews": "Customer Reviews",
                "view_more": "View More",
                "data_not_found": "No Data Found.",
                "products": "Products",
                "books": "Books",
                "services": "Services"
            },
            "spFilter": {
                "all": "All"
            },
            "statistic": {
                "my_statistics": "My Statistics"
            },
            "return_item_model": {
                "return": "Return",
                "attention": "Attention",
                "return_text__": "Make sure the returned item is delivered to the shipping company and you have a",
                "trackingNumber": "tracking number",
                "address": "Address : ",
                "name": "Name : ",
                "yes_i_have_tracking_number": "Yes i have tracking number",
                "deliver_by_hand": "Deliver by hand",
                "item": "Item :",
                "quantity": "Quantity :",
                "return_to_address": "Return to address :",
                "shipping_company": "Shipping Company",
                "shipping_company_:": "Shipping Company :",
                "reason": "Reason",
                "reason_:": "Reason :",
                "please_choose": "Please Choose.. ",
                "tracking_number": "Tracking Number :",
                "images": "Image :",
                "tap_to_make_cover": "Tap to make cover",
                "submit": "Submit",
                "delivery_address": "Delivery address :",
                "finish": "Finish",
                "success": "Success",
                "your_return_code": "Your return code :",
                "use_this_number_with_the": "Use this number with the",
                "store": "Store",
                "to_complete_return": "to complete return",
                "replacement": "Replacement",
                "expected_return_date": "Expected return date :"
            },
            "filter_type": {
                'service': 'Service Offered By',
                'book': "Book Offered By",
                'product': "Product Offered By",
            },
            "product_module": {
                "cancel_item": "Cancel Item",
                "payment_status": "Payment Status",
                "returned_rewards": "Returned Reward",
                "quantity_replaced": "Quantity Replaced",
                "quantity_returned": "Quantity Returned",
                "total": "Total",
                "ss_commission": "Student Store Commission",
                "service_online_link": "Service Link",
                "view_used_products": "View Used Products",
                "view_student_services": "View Student Services",
                "similar": "Similar",
                "reward_points": "Reward Points",
                "delivery_code": "Delivery Code",
                "amount_received": "Amount Received",
                'refunded_amount': "Refunded Amount",
                "please_add_a_card": "Please Select or Add A Card",
                "zip": "Zip",
                "guardian_details": "Guardian Details",
                "feedback_posted": "Feedback Success. Thanks for youe feedback.",
                "invalid_feedback": "Invalid Feedback",
                "personal_details": "Personal Details",
                "country": "Country",
                "city": "City",
                "state": "State",
                "address": "Full Address",
                "address_details": "Address Details",
                "gender": "Gender",
                "email": "Email",
                "mobile_number": "Number",
                "male": "Male",
                "female": "Female",
                "first_name": "First Name",
                "last_name": "Last Name",
                "user_details": "User Details",
                "ordered_by": "Ordered By",
                "decline": "Decline",
                "accept": "Accept",
                "refund": "Refund",
                "payment_done_by": "Payment done by",
                "service": "Services",
                "product": "Products",
                "book": "Books",
                "best-selling": "Best Selling",
                "promotion": "Promotion",
                "popular": "Popular",
                "top-rated": "Top Rated",
                "random": "Random",
                "latest": "Latest",
                "filter_by": "Filter By",
                "product_type": "Product Type",
                "category_and_subcategory": "Category & Subcategory",
                "select_category": "Select Category",
                "select_subcategory": "Select Subcategory",
                "search_brands": "Search Brand",
                "create_brands": "Search or Create Brand",
                "data_not_found": "No Records Available",
                "loading": "Loading...",
                "brands": "Brands",
                "duration": "Duration",
                "select_service_type": "Select Service Type",
                "product_condition": "Product Condition",
                "used_product": "Used Product",
                "author_and_publisher": "Author & Publisher",
                "city": "City",
                "select_city": "Select City",
                "rating": "Rating",
                "select_language": "Select Language",
                "language": "Languages",
                "sell_type": "Sell Type",
                "for_sale": "For Sale",
                "for_rent": "For Rent",
                "free": "Free",
                "publish": "Publish",
                "item_condition": "Item Condition",
                "brand": "Brand",
                "price": "Exclusive Price",
                "in_cart": "You Have {count} Item In Cart",
                "product_specifications": "{type} Specifications",
                "type_product": "Product",
                "type_service": "Service",
                "type_book": "Book",
                "service_type": "Service Type",
                "service_languages": "Service Languages",
                "delivery_type": "Delivery Type",
                "book_name": "Book Name",
                "product_name": "Product Name",
                "service_name": "Service Name",
                "deposit_amount": "Deposit Amount",
                "suitable_age": "Suitable Age",
                "book_cover": "Book Cover",
                "width_in_cm": "Width in Cm",
                "dimension_lwh": "Length, Width, Height (Cm)",
                "reviews": "Customer Reviews ({current}/{total})",
                "status": "Status",
                "total": "Total",
                "ship": "Ship",
                "order_no": "Order No",
                "shipping_company": "Via",
                "tracking_id": "Tracking ID",
                "expected_delivery_date": "Expected Delivery Date",
                "delivery_date": "Delivery Date",
                "deliver": "Deliver",
                "reason": "Reason",
                "cancel": "Cancel",
                "confirm_delivery": "Confirm Delivery",
                "return": "Return Item",
                "return_warning": "Make sure that, the returned item is delivered to the shipping company and you have a",
                "tracking_no": "Tracking ID",
                "attention": "Attention",
                "address": "Address",
                "name": "Name",
                "replacement": "Replacement",
                "return": "Return",
                "return_by_hand": "I will deliver myself",
                "i_have_tracking_no": "I have Tracking No",
                "accept": "Accept",
                "reject": "Reject",
                "seller": "Seller",
                "quantity": "Quantity",
                "points": "Points",
                "service_location": "Service Location",
                "product_location": "Product Location",
                "contact_seller": "Contact Seller",
                "payment_card": "Payment Card",
                "add_note_to_seller": "You can add notes to seller",
                "remove_from_cart": "Remove Item From Cart",
                "ask_for_cancellation": "Ask for cancellation",
                "please_insert_reason": "Please insert reason",
                "dispute": "Dispute",
                "dispute_details": "Dispute Details",
                "delivery_details": "Delivery Details",
                "return_details": "Return Details",
                "replacement_details": "Replacement Details",
                "decline": "Decline",
                "resolve_date": "Date of resolution",
                "resolve": "Resolve",
                "rating": "Ratings & Reviews",
                "resolve_message": "This dispute is resolved by the seller",
                "order_status_changed_message": "Order {status} successfully.",
                "resolved_info": "Your Item is canceled and amount will be credit to your account within 14 days from the date of resolution",
                "add_address": "Add Address",
                "add_payment_card": "Add Payemnt Card",
                "add": "Add",
                "edit": "Edit",
                "view": "View",
                "delete": "Delete",
                "action": "Action",
                "invalid_shipping_details": "Invalid shipping details",
                "tracking_number": "Tracking no should be 10 to 14 digit",
                "boost": "Boost",
                "boost_removed": "Boost Removed Successful",
                "boost_successful": "Boost Successful",
                "replacement_code": "Replacement Code",
                "return_code": "Return Code",
                "note_to_seller": "Notes",
                "note": "Notes",
                "view": "View",
                "agree_to_t_and_c": "By clicking Checkout button, You agree to our"
            },
            "genders": {
                "male": "Male",
                "female": "Female",
                "other": "Other"
            },
            "common": {
                "item-not-delivered-yet": "Item not delivered yet.",
                "loading_more_data": "Loading More Data...",
                "points": "Points",
                "save": "Save",
                "only_5_images": "You can select 5 images",
                "update": "Update",
                "total_earned_reward_value": "Total Given Reward Point Value",
                "total_earned_reward_points_given": "Total rewards points given",
                'marked_as_sold': "Successfully marked as sold",
                "where_sold": "Q1 - Where did you sell it?",
                "how_many_days": "Q2 - How many days did it take to sell it?",
                "more_than": "More than",
                "student_store": "On Student Store",
                "outside": "Outside",
                "payment_status": "Payment Status",
                "sold_out": "SOLD OUT",
                "is_promoted": "This item is promoted",
                "most_popular": "This item is promoted as most popular",
                "top_selling": "This item is promoted as top selling",
                "application_will_start": "Application will start at",
                "total_amount_transferred_to_vendor": "Total Received Amount",
                "total_student_store_commission": "SS Commission",
                "total_cool_company_commission": "CC Commission",
                "total_canceled_refunded_amount": "Total canceled refunded amount",
                "total_earned_reward_points": "Total reward points",
                "total_ordered_amount": "Total Ordered Amount",
                "payment_details": "Payment Details",
                "order_by": "Ordered By",
                "added_by": "Added By",
                "view_job": "View Job",
                "seller": "Filter By Seller",
                "cv": "CV",
                "view_cv": "View CV",
                "view_applicant": "View Applicant",
                "details": "Details",
                "job_title": "Job Title",
                "price_breakup": "Price Breakup",
                "no_data": "No Data Available",
                "original_total": "Total Before Rewards: ",
                "this_is_usable_reward_points": "This is the point which is used for products as per the distribution, You can change the value or continue with this points.",
                "usable_reward_point": "Usable Reward Points",
                "enter_reward_point": "Enter Reward Points",
                "original_price": "Original Price",
                "settlement_status": "Settlement Status",
                "amount_settled": "Amount Settled",
                "amount_settled_pending": "Settlement Pending",
                "add_address": "Add Address",
                "clear_filter": "Clear Filter",
                "search_by": "Search Result for: ",
                "close": "Close",
                "go_back": "Go Back",
                "confirm": "Confirm",
                "product_type": "Product Type",
                "send": "Send",
                "our_values": "Our Value",
                "our_services": "Our Services",
                "both": "Both",
                "view_all": "View All",
                "back": "Back",
                "import_product": "Import Product",
                "import_service": "Import Service",
                "import_book": "Import Book",
                "categories": "Categories",
                "authorize": "Authorize",
                "above": "& above",
                "status": "Status",
                "order_total_amount": "Order Total",
                "coming_soon": "Coming Soon",
                "acc_active": "Account Active",
                "acc_inactive": "Account Inactive",
                "visit_link": "Visit Link",
                "hours": "Hours",
                "upgrade": "Upgrade",
                "checkout": "Checkout",
                "privacy_policy": "Privacy Policies",
                "cookies_policy": "Cookies Policies",
                "and": "and",
                "agree_to_cookies": "I Understand",
                "free": "Free",
                "total": "Total",
                "price_with_vat": "Price Inclusive VAT",
                "login": "Login",
                "updated_at": "Updated At",
                "paid_amount": "Paid Amount",
                "unlimited": "Unlimited",
                "gateway_detail": "Payment Gateway",
                "transaction_status": 'Transaction Status',
                "order_number": "Order Number",
                "total_amount": "Total Amount",
                "order_date": "Order Date",
                "download_invoice": "Invoice",
                "resend": "Resend",
                "enter_email": "Enter Email",
                "event": "Event",
                "free": "Free",
                "contest": "Contest",
                "products": "Products",
                "services": "Services",
                "library": "Library",
                "jobs": "Jobs",
                "applicants": "Applicants",
                "year_old": "Year Old",
                "reward_points": "Reward Points",
                "contact_number": "Contact Number",
                "share_rewards": "Share Rewards",
                "in": "In",
                "search": "Search",
                "faqs": "FAQs",
                "total_amount": "Total",
                "no_new_notifications": "No New Notifications",
                "share": "Share",
                "out_of_stock": "Out of stock",
                "export": "Export",
                "upload_file": "Upload File",
                "category": "Category",
                "sub_category": "Sub Category",
                "location": "Address",
                "download_excel": "Download File",
                "import": "Import",
                "next": "Next",
                "reported": "Abuse Report Submitted",
                "register_title": "Register Title",
                "messages": "Messages",
                "message_sent": "Message Sent.",
                "write_message": "Write Message",
                "ask_seller": "Ask Seller",
                "send_message": "Send Message",
                "fill_message": "Enter Message Please",
                "online": "Online",
                "already_applied": "Already Participated",
                "boost_alert": "Please use your boost wisely, If you remove your boosting, before the time, your boost will be lost.",
                "add": "Add",
                "edit": "Edit",
                "view": "View",
                "delete": "Delete",
                "action": "Action",
                "report_abuse": "Report Abuse",
                "map": "View On Map",
                "location": "Location",
                "select": "Select",
                "title": "Title",
                "write_something": "Write Something",
                "soon": "Soon"
            },
            "job_status": {
                1: "Approved",
                0: "Pending Approval",
                3: "Expired",
                "accepted": "Accepted",
                "rejected": "Rejected",
                "pending": "Pending"
            },
            "contest_module": {
                "mode": "Mode (Online / Offline)",
                "meta_title": "Meta Title",
                "meta_keyword": "Meta Keywords",
                "meta_description": "Meta Description",
                "status": "Status",
                "hours": "Hours",
                "start_from": "Start from",
                "to": "To",
                "free_only": "Free Subscription Only",
                "free_cancel": "Free Cancellation",
                "user_type": "Available For",
                "participants": "Participants",
                "contest_cover": "Contest Cover",
                "event_cover": "Event Cover",
                "form_error": "Please fill required fields",
                "upload_files_here": "Select Cover Image",
                "select_files": "Select Image",
                "category": "Category",
                "sub_category": "Sub Category",
                "description": "Contest Description",
                "sponsor_detail": "Sponsor Detail",
                "new_contest": "New Contest",
                "contest_info": "Contest Info",
                "contest_description": "Contest Description",
                "contest_cover_image": "Contest Cover Image",
                "event_cover_image": "Event Cover Image",
                "title": "Title",
                "contest_type": "Contest Type...",
                "event_type": "Event Type...",
                "sponsers": "Sponsers",
                "next": "Next",
                "event_description": "Event Description",
                "event_info": "Event info",
                "contest_description": "Contest Description",
                "starting_date": "Starting Date",
                "starting_and_ending_time": "Starting And Ending Time : ",
                "starting_time": "Starting Time",
                "ending_time": "Ending Time",
                "end_date_and_time": "Ending Date & Time",
                "application_start_date": "Application Start Date",
                "application_end_date": "Application End Date",
                "participant_max_number": "Participant Max Number",
                "when_adding_a_": "When adding a paid competition, the competition fee depends mainly on the number of participants. The fee for one participant is 50 kr",
                "to_address": "To Address : ",
                "location": "Location",
                "add_new_address": "Add new address",
                "at_location": "At Location",
                "online": "Online",
                "meeting_link": "Meeting Link : ",
                "empty_is_unlimited": "( empty is unlimited )",
                "default": "default",
                "back": "Back",
                "next": "Next",
                "condition_of_joining": "Conditions of joining (if any) :",
                "education_level": "Education Level",
                "education_institution": "Educational Institution",
                "age_restriction": "Age restriction",
                "min": "Min Age",
                "max": "Max Age",
                "others": "Others",
                "required_upload_file": "Required upload file",
                "file_title": "File title",
                "the_jury": "The Jury",
                "add_new_contest": "Add New Contest",
                "add_new_form": "Add New Form",
                "subscription_fees": "Subscription Fees",
                "entry_cost_for_attendee": "Entry cost",
                "free_subscription": "Free",
                "use_cancellation_policy": "Use Cancellation Policy",
                "hour_s": "Hour/s",
                "from": "From",
                "to": "To",
                "deduct": "Deduct %",
                "number_of_winners": "Total number of winners",
                "winner_prize": "Winner Prizes",
                "0_winner_prize": "1st Winner Prize",
                "1_winner_prize": "2nd Winner Prize",
                "2_winner_prize": "3rd Winner Prize",
                "publish_contest": "Publish Contest",
                "publish_event": "Publish Event",
                "submit": "Submit",
                "new_event": "New Event",
                "my_address": "My Address",
                "categories": "Categories",
                "subCategories": "Sub Categories",
                "select": "Select",
                "contest": "Contest",
                "apply_discount": "Apply Discount",
                "discount_in_percentage": "Discount In Percentage",
                "discount_placeholder": "eg:10",
                "discounted_price": "Price after discount",
                "apply_reward_points": "Add Reward Points",
                "reward_points": "Reward Points",
                "reward_points_text": "The maximum number of reward points for this item is : ",
                "set_condition_of_joining": "Set condition of joining",
                "all": "All",
                "student": "Student",
                "edit_contest": "Edit Contest",
                "edit_event": "Edit Event",
                "target_location": "Target Location",
                "city": "City",
                "country": "Country",
                "the_amount_you_will_get_after_subtracting_commission_will_be": "The amount you will get after subtracting commission will be ",
                "registration_type": "Registration Type",
                "service_provider_type": "Service Provider Type",
                "max_participants_will_be": "Max participants",
                "set_min_participants": "Set Min Participants",
                "participant_min_number": "Participant Min Number",
                "edit": "Edit",
                "students": "Students",
                "companies": "Companies",
                "normal_users": "Buyers",
                "available_for": "Available For",
                "all": "All"
            },
            "contest_detail": {
                "condition_for_joining": "Conditions for Joining",
                "application_status": "Application Status",
                "contest_status": "Contest Status",
                "rating": "Rating & Feedback",
                "mode": "Mode (Online/Offline): ",
                "winners": "Winners",
                "event": "Event",
                "contest": "Contest",
                "hours": "hours",
                "reason": "Reason",
                "reupload": "Reupload Document",
                "description": "Description",
                "cancellation_policy": "Cancellation policy",
                "created_by": "Created by",
                "deduct": "Deduct",
                "deduction": "Deduction",
                "on_hold_alert_msg": "If you do not edit your contest/event within 7 days while it is on Hold then the contest/event will get cancelled automatically and amount will be refunded to all participants",
                "winner": "Winner : ",
                "winner_": "Winner",
                "the_jury": "Jury Members",
                "target_city": "Target City",
                "target_country": "Target Country",
                "participate_now": "Participate now",
                "publish_contest": "Publish Contest",
                "start": "Start",
                "participants": "Participants",
                "end_contest": "End Contest",
                "cancel_contest": "Cancel Contest",
                "select_a_winner": "Select a winner",
                "subscription_fees": "Subscription fees",
                "free": "Free",
                "sponsor": "Sponsor",
                "type": "Type",
                "application_start": "Application start",
                "application_end": "Application end",
                "contest_start_date": "Contest start date",
                "contest_start_and_end_time": "Contest start & end time",
                "event_location": "Event location",
                "event_start_date": "Event start date",
                "event_start_and_end_time": "Event start & end time",
                "already_participated": "Already participated",
                "select": "Select",
                "upload_document": "Upload Document",
                "upload_image": "Upload Image",
                "cancel": "Cancel",
                "hold_text": "The contest is put on hold because minimum participants condition is not fullfilled till the application end date, so we suggest you to edit and extend the dates of the contest or cancel and refund the participation amount of existing particpants if any.",
                "if_you_cancel_text": "If you cancel now, you will get",
                "as": "as",
                "will_be_dedcuted_text": "will be deducted according to the cancellation policy.",
                "prize": "Prize",
                "meeting_link": "Meeting Link",
                "ask": "Ask",
                "upload": "Upload",
                "rejected": "Rejected",
                "application_status": "Aplication Status",
                "sub_category": "Sub Category",
                "category": "Category",
                "file_required_to_participate": "File Required To Participate",
                "only_for_students": "Only for students",
                "only_for_companies": "Only for companies",
                "only_for_normal_user": "Only for normal user",
                "third_prize": "Third Prize",
                "second_prize": "Second Prize",
                "view_on_map": "View On Map",
                "application_cancelled": "Application Cancelled",
                "application_rejected": "Application Rejected",
                "message": "Message",
                "application_submitted": "Application Submitted",
                "upload": "Upload",
                "select_file": "Select File",
                "boost_successful": "Boost Successful",
                "boost_removed": "Boost Removed",
                "promotion": "Promotion",
                "best-selling": "Best Selling",
                "popular": "Popular",
                "edit": "Edit",
                "delete": "Delete",
                "deleted": "Delete Successful",
                "boost": "Boost",
                "application_date": "Application Date",
                "free_subscription": "Free",
                "status": "Status",
                "position": "Prize",
                "first": "First",
                "second": "Second",
                "third": "Third",
                "agree_to_t_and_c": "By clicking Checkout button, you agree to our",
                "terms_of_use_and_privacy_policy": "Terms and Conditions"
            },
            "education_level": {
                "pre_primary": "Pre Primary",
                "primary": "Primary",
                "high_school": "High School",
                "higher_secondary": "Higher Secondary",
                "graduation": "Graduation",
                "post_graduation": "Post Graduation",
                "phd": "PHD"
            },
            "target_audience": {
                "all": "All",
                "students": "Students",
                "companies": "Companies",
                "normal_users": "Normal Users"
            },

            "contest_mode": {
                "online": "Online",
                "offline": "At Location"
            },
            "application_status": {
                "completed": "Completed",
                "joined": "Joined",
                "rejected": "Rejected",
                "pending": "Pending",
                "canceled": "Canceled",
                "refund": "Refunded",
                "approved": "Approved",
                "document_updated": "Document Updated"
            },
            "participants": {
                "only_completed_user": "Participants with status Completed only can be selected as a winner(s)",
                "contest_full": "This contest is full",
                "by_me": "By Me",
                "by_creator": "By Creator",
                "payment_status": "Payment Status",
                "applied_on": "Applied On",
                "approve": "Approve",
                "reject": "Reject",
                "download_file": "Download File",
                "application_approved": "Application Approved",
                "application_rejected": "Application Rejected",
                "reason": "Reason",
                "select_winners": "Select Winners",
                "first": "First Winner",
                "second": "Second Winner",
                "third": "Third Winner",
                "submit_winner": "Submit Winner",
                "please_select_winners": "Please Select Winner(s)",
                "winners_submitted": "Winners Submitted",
                "third_winner": "3rd",
                "first_winner": "1st",
                "second_winner": "2nd"
            },
            "contest_landing": {
                "contests_promotions": "Promotions",
                "contests_closing_soon": "Closing Soon",
                "contests_random": "Random",
                "contests_latest": "Latest",
                "contests_most_popular": "Most Popular",
                "events_promotions": "Promotions",
                "events_closing_soon": "Closing Soon",
                "events_random": "Random",
                "events_latest": "Latest",
                "events_most_popular": "Most Popular"
            },
            "contest_filter": {
                "user_type": "Available For",
                "all": "All",
                "student": "Student",
                "contests": "Contest",
                "events": "Event",
                "categories": "Categories",
                "subCategories": "Sub Categories",
                "free_subscription_only": "Free Subscription Only",
                "free_cancellation": "Free Cancellation",
                "choose": "Choose",
                "price_type": "Price Type",
                "date": "Date",
                "contest_type": "Contest type",
                "from": "From",
                "to": "To",
                "at_location": "At Location",
                "online": "Online",
                "event_type": "Mode (Online / Offline)",
                "country": "Country",
                "city": "City",
                "distance": "Distance",
                "apply_filter": "Apply filter",
                "filter_by": "Filter By"
            },
            "alerts": {
                "delete": "Are you sure you want to delete this {item}",
                "deleted": "Delete Successful",
                "submitted": "Submitted Successfully",
                "updated": "Updated Successfully",
                "message_payment_success": "Payment successful",
                "message_payment_failed": "Payment failed,Please try again"
            },
            "rewards": {
                "reward_credit_date": "Points credited date: ",
                "current": "Current",
                "used": "Used",
                "earned": "Earned",
                "available_reward_pts": "Available reward pts: ",
                "pending_reward_pts": "Pending reward pts: ",
                "customer_reward_pt_value": "Reward Point Equivalent to: ",
                "order_number": "Order No: ",
                "created_at": "Date: ",
                "order_status": "Order Status",
                "used_reward_points": "Used Reward Points: ",
                "earned_reward_points": "Earned reward points: ",
                "reward_point_status": "Status: "
            },
            "contest_status": {
                "hold": "On Hold",
                "completed": "Completed",
                "pending": "Pending",
                "processing": "Processing",
                "verified": "Verified",
                "rejected": "Rejected",
                "canceled": "Canceled",
                "expired": "Expired"
            },
            "module_types": {
                "product": "Products",
                "contest": "Contests",
                "job": "Jobs"
            },
            "message_modes": {
                "seller": "Seller",
                "buyer": "Buyer"
            },
            "chats": {
                "mo_messages": "No Messages",
                "messages": "Messages",
                "seller": "Seller",
                "buyer": "Buyer",
                "send": "Send",
                "write": "Write a message.."
            },
            "status_messages": {
                "shipped": "Shipped Successfully",
                "completed": "Completed Successfully",
                "replaced": "Replaced Successfully",
                "returned": "Returned Successfully",
                "delivered": "Delivered Successfully",
                "declined": "Declined Successfully",
                "return_initiated": "Return initiated Successfully",
                "replacement_initiated": "Replacement initiated Successfully",
                "dispute_initiated": "Dispute initiated Successfully",
                "reviewed_by_seller": "Order Reviewed Successfully",
                "ask_to_cancel": "Aked for cancellation successful",
                "canceled": "Canceled Successfully",
                "resolved_to_customer": "Order Resolved to customer Successfully",
                "cancelation_request_declined": "Cancellation Request declined Successfully",
            },
            "verification_code": {
                "delivered": "Delivery Code",
                "replaced": "Replacement Code",
                "returned": "Return Code",
            },
            "package_type": {
                "product_package": "Product Package",
                "service_package": "Service Package",
                "book_package": "Book Package",
                "contest_package": "Contest Package",
                "job_package": "Job Package",
            },
            "drop_aspirant_type": {
                'global': "Global",
                'my_applicant': "My Applicants"
            },
            "message": {
                "upgrade_package_jobs": "Please upgrade your Job package to accept more applicants, you have accepted {used} out of {total} applicants on this job.",
                "job_inactive": "This Job is inactive",
                "invalid_email": "Invalid Email",
                "invalid_phone": "Invalid Phone",
                "only_5_images": "You can select 5 images",
                "price_must_be_9": "The final sale price must be lower than 999999.9, Users will not be able to buy this product",
                "please_add_personal_info_first": "Please add personal info first",
                "you_can_publish": "To present your CV, you need to have atleast one education details, Please first add personal information and than add education details, after that you can present your CV.",
                "cv_details_meg": "To complete your CV, you need to add atleast 1 education detail, otherwise your CV will not generate properly, Please add Personal information first after that add education details.",
                "invalid_guardian_contact_number": "Invalid Guardian Contact Number, Number can not be same as student's number",
                'payment_is_processing': "Payment is under process, you can ship or deliver when the status get updated to paid",
                "out_of_stock": "Please remove the product from the cart, it is just become out of stock",
                "quantity_exceeded": "Please decrease the quantity, the added quantity of product is grater than the current available quantity",
                "order_failed": "Opps!!, Order not Placed, The status of payment is failed.",
                "order_under_process": "Order placed successfully, but the status of payment is not confirmed, please check the product status after sometime.",
                "please_wait_payment_in_process": "Please do not press back button or refresh the browser, Payment is under process..",
                "orderFailed": "Order failed, please try again later",
                "please_add_tags": "Please add at least one tag",
                "employees_per_job_ad_reached": "Please upgrade your package, you have reached the max allowed limit of job application",
                "add_education_details": "Please add at least 1 education details",
                "min_max_lenth_5_13": "Length Must be 5 to 13, Alpha-Numeric",
                "min_max_lenth_10_13": "Length Must be 10 to 13,  Alpha-Numeric",
                "cart_value_less": "Cart Value should be less then 999999.99",
                "unable_to_add": "Unable to add item",
                "please_add_address": "Please add a default address, required for invoice.",
                "password_must_be_6": "Password should have atleast one uppercase, one lowercase, one special character other than space and atleast one number, length 6 to 16",
                "g_password_must_be_6": "Gaurdian Password should have atleast one uppercase, one lowercase, one special character other than space and atleast one number, length 6 to 16",
                "phone_already_exist": "Contact Number Already Exists",
                "enter_valid_email": "Enter Valid Email",
                "time_format_info": "Time format must be: HH:MM AM/PM",
                "time_format_info_end_time": "Time format must be: HH:MM AM/PM, There must be 1 hours gap between start time and end time",
                "unable_to_edit": "Unable To Edit",
                "stripe_customer_id_not_found": "Stripe Customer Id Not Found",
                "guardian_password_not_matched": "guardian password not matched",
                "email_already_exist": "Email Already Exists",
                "g_email_already_exist": "Guardian Email Already Exists",
                "contact_already_exist": "Contact Number Already Exists",
                "g_contact_already_exist": "Guardian Contact Number Already Exists",
                "keywords_tooltip": "You can add multiple keywords, max 50, use enter key to add multiple tags",
                "transaction-status-waiting": "Checking transaction status, Please do not close or click back button, Page will be redirected automatically.",
                "invalid_quantity": "Invalid Quantity",
                "invalid_price": "Invalid Price",
                "invalid_discount": "Invalid Discount",
                "invalid_quality": "Invalid Quality",
                "transaction-accepted-message": "Transaction Successful, Please Close The Widow To Continue...",
                "transaction-failed-message": "Transaction Failed, Please Close The Widow To Continue...",
                "no_more_quantity": "Can not add more quantity.",
                "contest_event_canceled": "Contest/Event Canceled",
                "already_applied": "You have already applied for this contest/event",
                'you_meet_this_joining_condition': "You meet this joining requirement.",
                'do_not_meet_joining_conditions': "You do not meet the joining conditions to participate this contest/event",
                'you_do_not_meet_this_joining_condition': "You do not meet this joining requirement.",
                "registered_successfully": "Registered Successfully",
                "complete_cv": "Please Complete your CV First",
                "please_select_valid_hours": "Please enter valid hours",
                "please_select_duration": "Please select duration",
                "please_select_nice_to_have_skills": "Please select nice to have skills",
                "price_must_be_greater_than_zero": "Price Must Be Greater Than 0",
                "upgrade_package_type": "Post Limit Reached, Please Upgrade Your {type}",
                "upgrade_package_type_with_count": "Post Limit Reached, You have already posted {used} available of {available}. Please upgrade your {type} to continue.",
                "upgrade_contest_package": "You can not post more contest(s), You have used {used_number_of_contest} available of {number_of_contest}. Please upgrade to continue.",
                "upgrade_event_package": "You can not post more event(s), You have used {used_number_of_event} available of {number_of_event}. Please upgrade to continue.",
                "package_updated": "Package Updated Successfully",
                "no_more_data_to_show": "No More Data To Show",
                "confirm_reward_share": "{points} Reward Points wil be sent to the number {number}, do you want to confirm the operation?",
                "invalid_reward_point": "Invalid Reward Point",
                "recurring_info": "After upgrade, your recurring payment will be started automatically",
                "please_add_a_address": "Please select or add a address",
                'student_detail_incomplete': 'Please complete your information by updating your profile first',
                "reward_shared_failed": "Reward Share Failed",
                "reward_shared": "Reward Successfully shared",
                "unable_to_import": "Unable to import, Please try again later",
                "imported_successfully": "Data Imported Successfully",
                "unable_to_add_to_fav": "Unable to add to favorite",
                "application_start_date": "Job Application not started at",
                "verify_email_modal_message": "Please check your email Inbox, We have sent you a verification email, Please verify your email address",
                "search_placeholder": "Search Name, SKU or ISBN / GTIN",
                "update_cv": "Please update your CV.",
                "invalid_card_details": "Invalid Card Details.",
                "file_size_info": "Please upload file less than 5 MB",
                "image_size_info": "Please select image less than 5 MB in size and greater than or equal to 720 X 720 resolution for better look and feel.",
                "supported_file_types": "Supported File Types",
                "please_add_a_winner_prize": "Please add at least 1 prize",
                "file_not_supported": 'File Not Supported, Please Retry.',
                "cancelled": "You have canceled recurring payments for this package",
                "cancel_subscription_note": "Do you want to cancel recurring payments for this package? Your package will automatically shift to free package of this module after this package's validity expires.",
                "unable_to_download_invoice": "Unable to download invoice",
                "transaction_failed": "Transaction Failed, Please try again.",
                "accept_cookies": "Student Store uses cookies to provide necessary website functionality, improve your experience and analyze our traffic. By using our website, you agree to",
                "ask_your_parent": "Ask Your Parent To Checkout",
                "ordered": "Order Successful",
                "invalid_code": "Invalid Code",
                "select_payment_card": "Please Select Payment Card",
                "email_verified_successfully": "Email Verified Successfully",
                "email_verification_failed": "Email Verification Failed",
                "select_multiple_images": "To select multiple images press and hold Ctrl/Command key and click on images.",
                "experience_is_max": "Total Experience can be greater than 50",
                "package_downgrading_notes": "Note: By Downgrading your package, you will lost your current boosting.",
                "download_cv": "Download Your CV",
                "generate_cv": "Please create your CV first.",
                "otp_verified": "Otp Verified Successfully",
                "enter_otp": "Please Enter Otp",
                "add_card": "Add Card",
                "invalid_otp": "Invalid Otp",
                "registered_successfully": "Registered Successfully",
                "password_mismatch": "Password and repeat password must be same",
                "under_implementation": "Under Implementation",
                "otp_sent": "OTP Sent Successfully",
                "link_resent": "Link Resent",
                "stripe_connected_successfully": "Stripe Connected Successfully.",
                "stripe_connection_failed": "Stripe Connection Failed, Please Retry.",
                "reauth_needed": "Verification Failed, Please Re-Auth",
                "go_to_home_page": "Go To Home Page",
                "verification_successful": "Verification Successful",
                "subscribed_successfully": "Subscribed Successfully",
                "enter_email": "Please Enter Email",
                "password_reset_success": "Password Reset Success",
                "please_wait": "Please wait..",
                "update_cv_first": "Please update your CV first",
                "applied_successfully": "Applied Successfully",
                "login_to_continue": "Please login to continue",
                "application_accepted": "Application Accepted",
                "application_rejected": "Application Rejected",
                "enter_valid_hours": "Please enter valid hours",
                "select_duration": "Please select duration",
                "select_nice_to_have_skills": "Please select nice to have skills",
                "select_category": "Please Select Category",
                "added_to_fav": "Added to Favorites",
                "removed_from_fav": "Removed from favorite list",
                "added_to_cart": "Added to Cart",
                "removed_from_cart": "Removed from Cart",
                "unable_to_add_to_cart": "Unable to add to cart",
                "please_enter_password": "Please Enter Password",
                "invalid_reward_points": "Invalid Reward Points",
                "invalid_contact_number": "Invalid Contact Number",
                "account_is_blocked": "Your Account is blocked, Please contact admin.",
                "login_with_parent": "Please login with parent to continue.",
                "need_parent_account": "Need Parent or Adult Accouunt to access this.",
                "please_fill": "Please fill all details",
                "saved": "Saved Successfully",
                "updated": "Updated Successfully",
                "activate": "Are you sure? you want to activate this item(s)?",
                "active": "Are you sure? you want to active this user(s)?",
                "inactive": "Are you sure? you want to inactive this user(s)?",
                "ban": "Are you sure? you want to ban this user(s)?",
                "joined": "Are you sure? you want to change the status as joined?",
                "processing": "Are you sure? you want to change the status as processing?",
                "verified": "Are you sure? you want to change the status as verified?",
                "rejected": "Are you sure? you want to change the status as rejected?",
                "publish": "Are you sure? you want to publish this item(s)?",
                "unpublish": "Are you sure? you want to unpublish this items(s)?",
                "delete": "Are you sure? you want to delete this items(s)?",
                "approved": "Are you sure? you want to approve this items(s)?",
                "export_all": "Export All",
                "operation_success": "Task Successfully Executed.",
                "operation_failed": "Operation Failed, Please try again later.",
                "sale_type": "Sale Type",
                "please_select": "Please Select ",
                "added_successfully": "Added successfully",
                "update_message": "Do you want to update this item?",
                "add_message": "Do you want to add this item?",
                "uploadFile": "Please upload a file",
                "downloadCSVTemplate": "Download CSV template from ",
                "somethingWentWrong": "Something went wrong",
                "noFeedbackToShow": "No feedback to show",
                "noData": "No Data to show",
                "will_be_send_to": "Sending notification to",
                "import": "Please download this Excel file and fill all the details accordingly. After that you can upload the file to import your items.",
                "inValidInput": "Invalid Input !!! Kindly provide correct details",
                "no_reason_filled": "Please Enter a valid reason.",
                "add_shippment_company": "Please Add a Shippment Company Name.",
                "tracking_number_return": "Please add a tracking number.",
                "expected_delivery_date_return": "Please add a expected delivery date.",
                "saved_contest": "Contest Saved Successfully",
                "saved_event": "Event Saved Successfully",
                "mo_page_data": "Please Add Data From Backend"
            },
            "chart_labels": {
                "total_spends": "Total Spends",
                "amount_received": "Amount Received",
                "amount_pending": "Amount Pending",
                "amount_under_process": "Amount Under Process",
                "commission": "Commission",
                "no_data": "No Data",
                "order_completed": "Order Completed",
                "order_delivered": "Order Delivered",
                "order_under_process": "Order Processing",
                "total_amount_refunded": "Refunded Amounts",
                "total_books": "Books",
                "total_companies": "Companies",
                "total_contests": "Contests",
                "total_earnings": "Earnings",
                "total_events": "Events",
                "total_jobs": "Jobs",
                "total_normal_users": "Buyers",
                "total_orders": "Orders",
                "total_products": "Products",
                "total_returned_items": "Returned Items",
                "total_services": "Services",
                "total_students": "Students",
                "top_selling_products": "Top Selling Products",
                "product_sales": "Sales of 7 days",
                "recent_orders": "Recent Orders",
                "total_sales_book": "Book",
                "total_sales_service": "Service",
                "total_sales_product": "Product",
                "total_sales_contest": "Contest",
                "total_sales_event": "Events",
                "total_sales_book_list": "Books",
                "total_sales_contest_list": "Contest",
                "total_sales_event_list": "Events",
                "total_sales_product_list": "Products",
                "total_sales_service_list": "Services",
                "top_sales_book_list": "Books",
                "top_sales_contest_list": "Contest",
                "top_sales_event_list": "Events",
                "top_sales_product_list": "Products",
                "top_sales_service_list": "Services",
                "new_orders": "New Orders",
                "most_sold_items": "Most Sold Items",
                "total_sell_count": "Quantity Sold",
                "total_order_count": "Total Orders",
                "type": "Item Type",
                "quantity": "Quantity",
                "brand": "Brand",
                "category": "Category",
                "subcategory": "Subcategory",
                "seller": "Seller",
                "buyer": "Buyer",
                "status": "Status",
                "sales_report": "Sales Reports",
                "report": "Combined Sales Amount of Product, Service, Books, Contest and Events",
                "sales": "Total Sales",
                "today": "Today",
                "week": "This Week",
                "week_sale": "This week's sales",
                "month": "This Month",
                "dashboard": "Dashboard"
            },
            "settings": {
                "shipping_settings": "Shipping Criteria",
                "discount_percent": "Discount Percentage",
                "order_amount_from": "Order Amount From",
                "order_amount_to": "Order Amount To"
            },
            "account_menus": {
                "my_profile": "My Profile",
                "cool_company": "Cool Company Dashboard",
                "switch_to_seller_menu": "Switch To Seller",
                "switch_to_byuer_menu": "Switch To Buyer",
                "statistics": "Statistics",
                "contest": "Contest & Events",
                "my_contest_event": "My Content & Events",
                "create_contest": "Create a Contest",
                "create_event": "Create a Event",
                "create_job": "Create a Job",
                "my_job": "My Jobs",
                "jobs": "Jobs",
                "products_services_books": "Products, Services & Books",
                "my_listings": "My Listings",
                "create_products": "Create Product, Service & Book",
                "orders": "Orders",
                "shipping_settings": "Shipping Settings",
                "personal_information": "Personal Information",
                "edit_profile": "Edit Profile",
                "stripe_integration": "Stripe Integration",
                "address_management": "Address Management",
                "payment_cards": "Payment Methods",
                "my_jobs": "My Jobs",
                "my_rewards": "My Rewards",
                "my_cv": "My CV",
                "my_contest": "My Contest",
                "responsibilities": "Responsibilities"
            },
            "bank_type": {
                "1": "Local",
                "2": "International",
                "3": "Paypal"
            },
            "search_types": {
                "product": "Product",
                "service": "Service",
                "book": "Book",
                "event": "Event",
                "contest": "Contest",
                "job": "Job"
            },
            "cc_dashboard": {
                "assignment_name": "Assignment Name",
                "enter_amount": "Enter Amount",
                "dashboard": "Cool Company Statistics",
                "calculation": "Calculation",
                "assignments": "Assignments",
                "amount": "Amount",
                "your_earning_will_be": "Your Earning Will Be",
                "date": "Date",
                "totalBudget": "Total Budget",
                "assignmentId": "Assignment ID",
                "agreementId": "Agreement ID",
                "bdaId": "BDA ID",
                "actions": "Actions",
                "reports": "Reports",
                "dateFrom": "Date From",
                "dateTo": "Date To",
                "totalHours": "Total Hours",
                "invoices": "Invoice",
                "bankName": "Bank Name",
                "bankAccountNo": "Account No",
                "paymentAccountTypeId": "Payment Account Type Id",
                "check_status": "Check Status",
                "invoice_not_available": "Invoice Not Available",
                "invoice_paid": "Invoice Paid"
            },
            "price_breakups": {
                "vat_value": "Vat",
                "mainPrice": 'Price',
                "price_with_vat": "Price with VAT",
                "ss_commission": "Student Store Commission",
                "cc_commission_val": "Cool Company Commission",
                "cc_social_fee_val": "Cool Company Social Fee",
                "cc_salary_tax_val": "Cool Company Salary Tax",
                "totalAmountToBeDisplayed": "Total Sale Amount",
                'earned_reward_points': "Reward Points"
            },
            "stripe_integration": {
                "fund_transfer_history": "Fund Transfer History",
                "title": 'Title',
                "price": "Price",
                "category": "Category",
                "subcategory": "Sub Category",
                "statistics": "Statistics",
                "top_selling_books": "Top Selling Books",
                "top_selling_product": "Top Selling Products",
                "top_selling_service": "Top Selling Services",
                "top_selling_contest": "Top Selling Contests",
                "id": "ID",
                "amount": "Amount",
                "currency": "Currency",
                "source_type": "Source Type",
                "created_at": "Created At",
                "transfer_group": "Transfer Group",
                "totalEarning": "Total Earning",
                "totalTransferred": "Total Transferred",
                "remaining": 'Remaining',
                "continue": "Continue...",
                "connected_successfully": "Your Stripe Account is connected Successfully",
                "link_generated": "Stripe Account Creation Link Generated, Please follow this link and fill all the required data.",
                "link_expiry": "Link Expiration: ",
                "active": "Active",
                "follow_link": "Click Here To Continue",
                "processing": "Processing",
                "reconnect": "Re-Connect with Stripe",
                "check_status": "Check Status",
                "stripe_integration": "Stripe Integration",
                "connect_with_stripe": "Connect with Stripe",
                "account_active": "Your Stripe Account is Active.",
                "connection_failed": "Account creation failed, Please retry.",
                "connection_processing": "Account creation in under process",
                "please_create_stripe_account": "No Stripe account, Please create a Stripe Account. Click on 'Connect with Stripe' button to continue...",
                "transaction_history": "Transaction History",
                "no_data": "No transactions",
                "checking_account_status": "Checking Account Status... Please Wait."
            },
            "default_menu": {
                "applicants_viewed": "Viewed Applicants",
                "service_menu_title": "Services",
                "information_menu_title": "Information",
                "our_offer_menu_title": "Our Offers",
                "follow_us_menu_title": "Follow Us",
                "copyright": "© Copyright",
                "all_rights_reserved": "All Rights Reserved",
                "students_offer": "Student's Offer",
                "library": "Library",
                "company_books": "Company Books",
                "student_books": "Student Books",
                "products": "Products",
                "company_products": "Company Products",
                "student_products": "Student Products",
                "services": "Services",
                "student_services": "Student Services",
                "company_services": "Company Services",
                "jobs": "Jobs",
                "applicants": "Applicants",
                "contest": 'Contest',
                "contest_events": "Contest & Events",
                "company_contests": "Company Contest",
                "student_contests": "Student Contest",
                "company_events": "Company Events",
                "student_events": "Student Events",
                "switch_to_seller": "Switch to Seller",
                "switch_to_buyer": "Switch to Buyer",
                "go_to_cart": "GO TO CART",
                "notifications": "Notifications",
                "account": "Account",
                "my_packages": "My Packages",
                "my_orders": "My Orders",
                "my_favorites": "My Favorites",
                "messages": "Messages",
                "change_password": "Change Password",
                "logout": "Logout",
                "login": "Login",
                "registration": "Registration",
                "register_as_sp": 'Register as a service provider',
                "register_as_student": "Register As Student",
                "register_as_buyer": "Register As Buyer",
                "address": "Address",
                "phone": "Phone",
                "email": "Email",
                "mobile": "Mobile",
                "org_number": "Org. Number",
            },
            "pages": {
                "home": "Home",
                "about": "About",
                "contact_us": "Contact Us",
                "faqs": "FAQ's",
                "why_join_us": "Why to join us",
                "why_to_join_us": "Why to join us",
                "user_detail": "User Details",
                "contact_us": "Contact Us",
                "get_in_touch": "Get in Touch",
                "payment_method": "Payment Method",
                "reward_point_used": "Reward Points Used",
                "max_reward_points_can_be_used": "Max {points} Reward Points You Can Use"
            },
            "contact_us": {
                "contacting_for": "Contacting For",
                "full_name": "Full Name",
                "phone": "Phone",
                "email": "Email",
                "message": "Message",
                "send": 'Send',
                "message_sent": "Message Sent Successfully, We will contact you soon."
            },
            "order_success": {
                "order_status": "Order Status",
                "order_successfully_submitted": "Order Successfully Submitted",
            },
            "cart": {
                "stripe": "Stripe",
                "klarna": "Klarna",
                "swish": "Swish",
                "use_reward_points": "Use Reward Points",
                "reward_points_value": "Reward Points value",
                "terms_of_use_and_Privacy_policy": "Terms and Conditions",
                "continue_shopping": "Continue Shopping",
                "empty_cart": "Empty Cart"
            },
            "my_orders": {
                "all": "All",
                "products": "Products",
                "services": "Services",
                "books": "Books",
                "contest": "Contest",
                "package": "Package"

            },
            "order_Success": {
                "order_successfully_submitted": "Order Successfully Submitted"

            },
            "product_and_serviceinfo": {
                "review": "Review",
                "from": "From",
                "%_off": "% Off"
            },
            "products": {
                "view_all": "View All"
            },
            "buy_now": {
                "stripe": "Stripe",
                "klarna": "Klarna",
                "swish": "Swish",
                "use_reward_points": "Use Reward Points",
                "reward_points_value": "Reward Points value",
                "sub-total": "Sub-Total",
                "total": "Total",
                "terms_of_use_and_privacy_policy": "terms of use and Privacy Policy",
                "continue_shopping": "Continue Shopping"

            },
            "education_for": {
                "education_details": "Education Details"
            },
            "experience_for": {
                "currently_working": "Currently Working"
            },
            "edit_cv": {
                "active_?": "Present My CV?",
                "save": "Save"
            },
            "edit-profile": {
                " display_my_contact_number": "Display My Contact Number",
                "display_my_email_address": "Display My Email Address"
            },
            "my_packages": {
                "payment_method_(stripe)": "Payment Method (STRIPE)",
                "add_card": "Add Card"
            },
            "menu": {
                "send_otp": "We have sent an OTP to your registered mobile no.",
                "enter_password": "Enter Password",
                " enter_otp": "Enter Otp"

            },
            "scroll_top": {
                "top": "Top"
            },
            "main_layout": {
                "layout": "Layout"

            },
            "not_found": {
                "page_not_found": "Sorry! Page not found.",
                "deleted_page": "Unfortunately the page you are looking for has been moved or deleted.",
                "home_page": "GO TO HOME PAGE"
            },
            "card_info": {
                "code": "The Bro Code",
                "text": "By: Barney Stinson , With Matt Kuhn",
                "text_country": " Stenbocksgatan 20, 523 35 Ulricehamn, Sweden",
                "price": "50.00 SEK",
                "second_price": "325.00 SEK",
                "add_card": "Add To Card"
            },
            "import_modal": {
                "back": "Back",
                "close": "Close"
            }
        }
    }
}