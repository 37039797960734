import routes from "../router"
// import { cLog } from "./Common"
import { formatRoute } from 'react-router-named-routes';


export const getRoute = (name) => {
    return routes.find(r => r.routeName === name)
}

export const getPath = (name, params = null) => {
    let route = routes.find(r => r.routeName === name)
    let path = route?.path
    if (params && path)
        return formatRoute(path, params)
    else
        return path
}