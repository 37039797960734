import Cookies from 'js-cookie';
import { createStore } from 'redux';
import { decryptUserData } from '../helpers/Common';
import Constants from '../localization/Constants';

const userData = (localStorage.getItem('userData')) ? JSON.parse(localStorage.getItem('userData')) : null;
const menu = (Cookies.get('userToken') && localStorage.getItem('menu')) ? localStorage.getItem('menu') : 'default';
const langSelected = localStorage.getItem("langSelected") ?? "Language"

const initialState = {
    user: userData,
    token: Cookies.get('userToken'),
    lang: 'en',
    langSelected: langSelected,
    langs: null,
    langData: Constants,
    menu: menu,
    categoryProducts: null,
    categoryServices: null,
    categoryBooks: null,
    cartItems: null,
    notifications: [],
    settings: null,
    pages: null,
    messageCount: "",
    chat: {
        contacts: [],
        chats: [],
    }
}

const changeState = (state = initialState, { type, ...rest }) => {
    switch (type) {
        case 'set':
            return { ...state, ...rest }
        default:
            return state
    }
}

const store = createStore(changeState)
export default store