import moment from 'moment';
import React from "react";
import CryptoJS from "react-native-crypto-js";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
import { toast } from "react-toastify";
import { customSelectStyles } from "../config";
import Text from "../localization/Text";
import store from "../store";
import LoadingBox from "../views/Components/Common/LoadingBox";
import Encrypted from "./Encrypted";
import { getPath } from "./RouteHelper";
const label = "labels.product_module"

const dateFormat = "DD-MM-yyyy";
const timeFormat = "hh:mm:ss a";

export const removeBackDrop = () => {
    let d = document.getElementsByClassName("modal-backdrop")
    if (d?.length > 0) {
        d[0].classList.remove('modal-backdrop')
        document.getElementById("body").style.overflow = "visible"
        document.getElementById("body").style.padding = 0
    }
}

export const numberFormat = (money, languageCode = 'se', countryCode = 'SE') => {
    if (money) {
        return new Intl.NumberFormat(`${languageCode}-${countryCode}`).format(money);
    } else {
        return 0
    }
}
export const StripeStatus = {
    "pending": "1",
    "processing": "2",
    "active": "3",
    "failed": "4"
}
export const getProductPrice = (product) => {
    return product?.is_on_offer === 1 ? product?.discounted_price : product?.price
}
export function Encrypt(value) {
    if (isValid(value)) {
        return CryptoJS.AES.encrypt(value, "secret_key_Student_Store@2020").toString()
    } else {
        return value
    }
}
export function Decrypt(value = "") {
    if (isValid(value, null, true)) {
        let bytes = CryptoJS.AES.decrypt(value, "secret_key_Student_Store@2020");
        let originalText = bytes.toString(CryptoJS.enc.Utf8);
        // let originalText = bytes.toString();
        return originalText !== "" ? originalText : value
    } else {
        return value
    }
}
export function md5E(value = "", key) {
    if (isValid(value)) {
        let bytes = CryptoJS.MD5(value, key);
        let originalText = bytes.toString();
        // let originalText = bytes.toString();
        return originalText !== "" ? originalText : value
    } else {
        return value
    }
}
// DD-MM-YYYY
export const formatDate = (value) => {
    return moment(value).format(dateFormat);
}

export const formatDateByFormat = (value, format) => {
    let d = moment(value).format(format);
    if (d != "Invalid date") {
        return d
    } else {
        return ""
    }
}

export function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}
export function subDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() - days);
    return result;
}
export const formatTime = (value) => {
    //return this.datePipe.transform(value,this.timeFormat);
    const time = value.split(':');
    const formattedTime = "";
    const time_0 = parseInt(time[0]);
    const time_1 = parseInt(time[1]);
    //cLog(time);
    if (time[0] <= 12)
        formattedTime = "" + time_0;
    else
        formattedTime = time_0 - 12;

    formattedTime = formattedTime + ":" + time_1;

    if (time_0 >= 12 && time_1 >= 0)
        formattedTime = formattedTime + " PM";
    else
        formattedTime = formattedTime + " AM";

    //cLog("formattedTime : ", formattedTime);
    return formattedTime;
}



// log only on development
export const cLog = (log, log2 = "") => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(log, log2);
    } else {
        return false;
    }
}

export const getDropDownListFromLabels = (object) => {
    let object_list = [];
    Object.keys(object).map(key => {
        let obj = {
            label: object[key],
            value: key
        }
        object_list.push(obj)
    })
    return object_list;
}

export const camelCase = (str) => {
    if (str !== undefined) {
        return str.replace('-', ' ').replace(/\s(.)/g, function (a) {
            return a.toUpperCase();
        }).replace(/\s/g, '').replace(/^(.)/, function (b) {
            return b.toLowerCase();
        });
    }
}

export const getUrl = (url, page, perPage) => {
    return `${url}?page=${page}&per_page_record=${perPage}`
}
let id = 0
export const uuid = (prefix) => {
    id++
    return `${prefix}-${id}`;
}
export const serialize = (obj, prefix) => {
    var str = [],
        p;
    for (p in obj) {
        if (obj.hasOwnProperty(p)) {
            var k = prefix ? prefix + "[" + p + "]" : p,
                v = obj[p];
            str.push((v !== null && typeof v === "object") ?
                serialize(v, k) :
                encodeURIComponent(k) + "=" + encodeURIComponent(v));
        }
    }
    return str.join("&");
}
export const PasteNumberOnly = e => {
    var text = e.clipboardData.getData('Text')
    var valid = true
    if (e.type === 'paste') {
        var regex = /[0-9]|\./;
        if (!regex.test(text)) {
            valid = false
        }
    }
    if (!valid)
        return e.preventDefault();
}
export const ParseInt = (value, defaultValue = 0) => {
    var number = parseInt(value)
    // cLog("num", value);
    if (String(number) === "NaN") {
        return defaultValue
    } else {
        return number
    }
}
export const NumberOnlyValue = (e, callback = () => { }, dot = false, min = null, max = null, length = false) => {
    // var numberRegex = /^\s*(\d+|\.\d+|\d+\.\d+|\d+\.)\s*$/
    var numberRegex = dot ? /[^\d.]/g : /[^\d]/g
    let s = e
    s.target.value = String(e?.target?.value).replace(numberRegex, "")
    if (dot) {
        s.target.value = String(s?.target?.value).replace(/[\.]/g, function (match, offset, all) {
            return match === "." ? (all.indexOf(".") === offset ? '.' : '') : '';
        })
        s.target.value = String(s?.target?.value).replace(/^(\d+.?\d{0,2})\d*$/, "$1")
        // s.target.value = String(s?.target?.value).replace(/^[+-]?[1-9]\d{0,2}(?:\.\d{3})+$/, "$1")
    }
    if (!length) {
        if (min !== null) {
            s.target.value = (parseInt(s.target.value) >= parseInt(min)) ? s.target.value : min
        }
        if (max !== null) {
            s.target.value = (parseInt(s.target.value) <= parseInt(max)) ? s.target.value : s.target.value === "" ? "" : max
        }
    } else {
        s.target.value = String(s.target.value).substring(0, max)
        if (min !== null) {
            s.target.value = (parseInt(s.target.value) >= parseInt(min)) ? s.target.value : min
        }
    }
    s.target.value = String(s.target.value).replace(/^0+/, '0');
    cLog(String(s.target.value))
    callback(s)
}
export const NumberOnly = (e) => {
    let val = (e.target.value)

    var specialKeys = [18, 65, 67, 86, 88]
    var keyCode = e.which ? e.which : e.keyCode
    // cLog(val, e);
    // cLog(keyCode)
    var valid = ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105) || (keyCode === 8) || (keyCode === 9) || keyCode === 190 || keyCode === 37 || keyCode === 39 || keyCode === 110);
    if (e.ctrlKey && specialKeys.indexOf(keyCode) !== -1) {
        valid = true
    }
    if (!valid) {
        return e.preventDefault();
    }
}
export const getPlaceholder = (loading = true, title) => {
    if (loading) {
        return <div><i className="fa fa-spinner fa-pulse"></i> <Text id={`${label}.loading`}>Loading..</Text></div>
    } else {
        return title
    }
}
export const isInvalid = (invalid, key) => {
    if (invalid) {
        return invalid.includes(key)
    } else {
        return false
    }
}

export const DropDown = (options, loading, value, placeHolder, onChange = () => { }, multi = false, clear = false, disable = false, key = "", invalid = []) => {
    return (<Select isOptionDisabled={(option) => option.disabled} name={key} isMulti={multi} isClearable={clear} styles={customSelectStyles} value={((value !== undefined) ? value : null)} isDisabled={(loading) ? true : ((options) ? disable : true)} placeholder={getPlaceholder(loading, placeHolder)} onChange={onChange} className={(isInvalid(invalid, key) ? 'is-invalid' : '')} classNamePrefix="select" options={options} />)
}

export const DropDownCreatable = (options, loading, value, placeHolder, onChange = () => { }, multi = false, clear = false, disable = false, key = "", invalid = []) => {
    return (<CreatableSelect name={key} isMulti={multi} isClearable={clear} styles={customSelectStyles} value={value} isDisabled={(loading) ? true : ((options) ? disable : true)} placeholder={getPlaceholder(loading, placeHolder)} onChange={onChange} className={(isInvalid(invalid, key) ? 'is-invalid' : '')} classNamePrefix="select" options={options} />)
}

export const getSelectValue = (option) => {
    if (option) {
        return option.value
    } else {
        return null
    }
}
export const getSelectValueMulti = (option, get = "value") => {
    let op = []
    if (option) {
        option.forEach(d => {
            op.push(d[get])
        })
        return op;
    } else {
        return null
    }
}
export const setSelectOption = (array = [], name = null, id = null, label = null) => {
    let op = []
    if (array !== null) {
        if (typeof array === "object" && array.length > 0) {
            array.forEach(d => {
                if (name && id) {
                    if (label) {
                        op.push({
                            label: label[d[name]],
                            value: id === "object" ? d : d[id]
                        })
                    } else {
                        op.push({
                            label: d[name],
                            value: id === "object" ? d : d[id]
                        })
                    }
                } else {
                    if (label) {
                        op.push({
                            label: label[d],
                            value: d
                        })
                    } else {
                        op.push({
                            label: d,
                            value: d
                        })
                    }
                }
            })
        }
    }
    return op
}
export const IsProduct = (props) => {
    if (props.check === 'product') {
        return props.children
    } else {
        return null
    }
}
export const IsService = (props) => {
    if (props.check === 'service') {
        return props.children
    } else {
        return null
    }
}
export const IsBook = (props) => {
    if (props.check === 'book') {
        return props.children
    } else {
        return null
    }
}
export const IsUsed = (props) => {
    if (props.check === 1) {
        return props.children
    } else {
        return null
    }
}
export const IsContest = (props) => {
    if (props.check === 'contest') {
        return props.children
    } else {
        return null
    }
}
export function checkMobileNumberFormat(mobileNo) {
    const mobileNoCheck = /^[0-9]{10,10}$/;
    return mobileNoCheck.test(mobileNo);
};

/// for filter
export const setSelectAttributes = (attributes) => {
    let options = []
    if (attributes) {
        attributes.forEach((a, i) => {
            let ops = []
            if (a.bucket_group !== undefined) {
                a.bucket_group.bucket_group_attributes.forEach((d, x) => {
                    if (d?.attribute_details !== null) {

                        if (a.bucket_group?.type == "list") {
                            ops.push({
                                ...d,
                                selected: false
                            })
                        } else {
                            ops.push({
                                ...d,
                                value: ""
                            })
                        }
                    }
                })
            }
            options.push({
                ...a,
                bucket_group: {
                    ...a.bucket_group,
                    is_multiple: 1,
                    bucket_group_attributes: ops
                }
            })
        })
    }
    // cLog(options)
    return options
}

/// for bucket_group_attributes_2
export const setSelectAttributesAddMore = (attributes) => {
    let options = []
    if (attributes) {
        attributes.forEach((a, i) => {
            options.push({
                ...a,
                bucket_group: {
                    ...a.bucket_group,
                    bucket_group_attributes_2: []
                }
            })
        })
    }
    // cLog(options)
    return options
}
// for dropdown selection on cart
export const getOptionsFromAttributesSelected = (attributes) => {
    let options = []
    if (attributes) {
        attributes.forEach((a, i) => {
            let ops = []
            if (a.bucket_group !== undefined) {
                a.bucket_group.bucket_group_attributes.forEach((d, x) => {
                    if (d?.attribute_details !== null) {
                        if (d?.selected) {
                            ops.push({
                                value: {
                                    ...d,
                                    index: x,
                                    selected: false,
                                    group: a?.bucket_group?.group_name
                                },
                                label: d?.attribute_details?.name,
                            })
                        }
                    }
                })
            }
            // if (ops?.length > 0)
            options.push(ops)
        })
    }
    cLog("options", options)
    return options
}

// dropdown for add 
export const getOptionsFromAttributes = (attributes) => {
    let options = []
    if (attributes) {
        attributes.forEach((a, i) => {
            let ops = []
            if (a.bucket_group !== undefined) {
                a.bucket_group.bucket_group_attributes.forEach((d, x) => {
                    if (d?.attribute_details !== null) {
                        ops.push({
                            value: {
                                ...d,
                                index: x,
                                selected: false
                            },
                            label: d?.attribute_details?.name
                        })
                    }
                })
            }
            options.push(ops)
        })
    }
    // cLog(options)
    return options
}
export const calculate_age = (dob1) => {
    var today = new Date();
    var birthDate = new Date(dob1);  // create a date object directly from `dob1` argument
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age_now--;
    }
    //cLog(age_now);
    return age_now;
}

export const paymentCardType = (cardNumber) => {
    const mastercard = /^5[1-5][0-9]{5,}|222[1-9][0-9]{3,}|22[3-9][0-9]{4,}|2[3-6][0-9]{5,}|27[01][0-9]{4,}|2720[0-9]{3,}$/;
    if (mastercard.test(cardNumber))
        return "mastercard";

    const americanexpress = /^3[47][0-9]{5,}$/;
    if (americanexpress.test(cardNumber))
        return "americanexpress";

    const visa = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
    if (visa.test(cardNumber))
        return "visa";

    const dinersclub = /^3(?:0[0-5]|[68][0-9])[0-9]{4,}$/;
    if (dinersclub.test(cardNumber))
        return "dinersclub";

    const discover = /^6(?:011|5[0-9]{2})[0-9]{3,}$/;
    if (discover.test(cardNumber))
        return "discover";

    const jcb = /^(?:2131|1800|35[0-9]{3})[0-9]{3,}$/;
    if (jcb.test(cardNumber))
        return "jcb";

    return null;
}

export const formatPhone = (value) => {
    const currentValue = String(value).replace(/[^\d]/g, '');
    const cvLength = currentValue.length;

    // returns: "x", "xx", "xxx"
    if (cvLength < 4) return currentValue;

    // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
    if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;

    // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
}

export const logError = error => {
    cLog(error);
    toast(error, { type: toast.TYPE.ERROR })
}

export const Loader = (props) => {
    if (props.loading !== undefined && props.loading === false)
        return (props.children)
    else
        return (<LoadingBox {...props} />)
}
export const ErrToast = (msg, data = null, time = 3000) => {
    toast.error(<Text id={msg} data={data}>{msg}</Text>, {
        autoClose: time,
        type: toast.TYPE.ERROR
    })
}
export const WarningToast = (msg, data = null, time = 3000) => {
    toast.error(<Text id={msg} data={data}>{msg}</Text>, {
        autoClose: time,
        type: toast.TYPE.WARNING
    })
}
export const SuccessToast = (msg, data = null, time = 3000) => {
    toast.info(<Text id={msg} data={data}>{msg}</Text>, {
        autoClose: time,
        type: toast.TYPE.SUCCESS
    })
}
export const isFloat = (n) => {
    // return Number(n) === n && n % 1 !== 0;
    return String(n).includes(".")
}
export const currencyFormat = (money, languageCode = 'sv', countryCode = 'SE', currency = 'SEK') => {
    if (money) {
        return new Intl.NumberFormat(`${languageCode}-${countryCode}`, {
            currency: currency,
            style: 'currency',
            // minimumFractionDigits: isFloat(money) ? 2 : 0,
            maximumFractionDigits: isFloat(money) ? 2 : 0,
        }).format(money);
    } else {
        return 0
    }
}
export const text = (key, text, data = null, string = false) => {
    return <Text data={data} id={key}>{text ?? String(key).split(".").pop().replace(/_/g, " ")}</Text>
}
export const joinArray = (by = " ", array) => {
    if (typeof array === 'object') {
        return array.join(by)
    } else {
        return array
    }
}
export const getLogicalDate = (date, cDate = null) => {
    if (date) {
        var GivenDate = date;
        var CurrentDate = cDate ?? new Date();
        GivenDate = new Date(GivenDate);

        if (GivenDate > CurrentDate) {
            return formatDateByFormat(GivenDate, "YYYY-MM-DD")
        } else {
            return formatDateByFormat(CurrentDate, "YYYY-MM-DD")
        }
    } else {
        return formatDateByFormat(CurrentDate, "YYYY-MM-DD")
    }
}
export const validateGivenDate = (date, cDate, disableCurrent = false) => {
    if (date) {
        var GivenDate = date;
        var CompareDate = cDate
        var CurrentDate = new Date();
        GivenDate = new Date(GivenDate);
        CompareDate = new Date(CompareDate);
        cLog(GivenDate, CompareDate)
        if (disableCurrent) {
            if (GivenDate >= CompareDate)
                return true
            else
                return false
        } else {
            if (GivenDate > CurrentDate) {
                if (GivenDate >= CompareDate)
                    return true
                else
                    return false
            } else {
                return false
            }
        }
    } else {
        return true
    }
}
export const checkHttp = (url) => {
    if (url?.includes('https://') || url?.includes('http://'))
        return url
    else {
        return 'http://' + url;
    }
}

export function timeDiffCalc(dateFuture, dateNow) {
    let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

    // calculate days
    const days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;
    cLog('calculated days', days);

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;
    cLog('calculated hours', hours);

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;
    cLog('minutes', minutes);

    let totalHours = days >= 1 ? ((days * 24 + hours)) : hours;


    let difference = {
        days: days,
        hours: hours,
        minutes: minutes,
        totalHours: totalHours
    };
    // if (days > 0) {
    //     difference += (days === 1) ? `${days} day, ` : `${days} days, `;
    // }

    // difference += (hours === 0 || hours === 1) ? `${hours} hour, ` : `${hours} hours, `;

    // difference += (minutes === 0 || hours === 1) ? `${minutes} minutes` : `${minutes} minutes`;

    return difference;
}
export function checkPasswordFormat(str) {
    cLog("password", str)
    var regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
    // let passwordPattern = /^(?=.[0-9])(?=.[!@#$%^&])[a-zA-Z0-9!@#$%^&]{6,16}$/;
    return regularExpression.test(str);
}
export const isValid = (val, extra = null, d = false) => {
    // cLog("valid s", typeof val)
    if (val !== undefined) {
        if (d === false) {
            if (typeof val !== "object") {
                val = String(val)?.replace(/^\s+|\s+$/g, '')
            }
        }
    }
    // cLog("valid", val)
    if (val !== "" && val !== null && val !== undefined && val !== extra)
        // if (val !== "" || val !== null || val !== undefined || val !== extra)
        return true
    else
        return false
}
export function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
}
export const trim = (text) => {
    // return text.replace(/^\s+|\s+$/gm, '');
    // return String(text).trim()
    return text
}
export const getApplicationStatus = (s) => {
    if (s === "pending") {
        return "text-warning";
    } else if (s === "rejected") {
        return "text-danger"
    }
    else if (s === "joined") {
        return "text-success"
    }
    else if (s === "canceled") {
        return "text-danger"
    } else if (s === 'refund') {
        return "text-info"
    } else if (s === "approved") {
        return "text-success"
    } else if (s === "document_updated") {
        return "text-warning"
    } else if (s === "document_updated") {
        return "text-warning"
    } else if (s === "verified") {
        return "text-success"
    } else if (s === "completed") {
        return "text-success"
    } else if (s === "hold") {
        return "text-warning"
    } else if (s === "expired") {
        return "text-danger"
    } else {
        return ""
    }
}
export function checkAlphaNumericFormat(str) {
    const format = /^[a-z0-9]+$/i;
    return format.test(str);
}
export function isValidHttpUrl(string) {
    // let url;
    // try {
    //     url = new URL(string);
    // } catch (_) {
    //     return false;
    // }
    // cLog("url", url)
    string = String(string).toLocaleLowerCase()
    // return url.protocol === "http:" || url.protocol === "https:";
    var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    cLog(res)
    if (res == null)
        return false;
    else
        return true;
}
export const getColor = (status) => {
    const colors = {
        pending: "#7aeb7a",
        joined: "green",
        rejected: "#FF585D",
        canceled: "#FF585D",
        completed: "green",
        document_updated: "#7aeb7a",
        "hold": "#7aeb7a",
        "verified": "green",
        "0": "#7aeb7a",
        "2": "green",
        "3": "#FF585D",
    }
    return colors[status] ?? "red"
}

export const getProfileImage = (user) => {
    if (user?.service_provider_detail) {
        return user?.service_provider_detail?.company_logo_thumb_path ?? "https://api.studentstore.se/no-image.png"
    } else if (user?.profile_pic_thumb_path) {
        return user?.profile_pic_thumb_path ?? "https://api.studentstore.se/no-image.png";
    } else {
        return user?.profile_pic_path ?? "https://api.studentstore.se/no-image.png"
    }
}
export const stringManipulation = (string, minLen, maxLen) => {

}
export const orderStatus = (status) => {
    let r = ""
    switch (status) {
        case "processing":
            r = "text-warning";
            break;
        case "shipped":
            r = "status-completed";
            break;
        case "delivered":
            r = "status-completed";
            break;
        case "completed":
            r = "status-completed";
            break;
        case "canceled":
            r = "status-rejected";
            break;
        case "return_initiated":
            r = "text-warning";
            break;
        case "returned":
            r = "text-warning";
            break;
        case "replacement_initiated":
            r = "text-warning";
            break;
        case "replaced":
            r = "text-warning";
            break;
        case "declined":
            r = "status-rejected";
            break;
        case "dispute_initiated":
            r = "text-danger";
            break;
        case "cancellation_request_accepted":
            r = "text-warning";
            break;
        case "reviewed_by_seller":
            r = "text-warning";
            break;
        case "ask_for_cancellation":
            r = "text-warning";
            break;
        case "ask_to_cancel":
            r = "text-warning";
            break;
        case "failed":
            r = "text-danger";
            break;
        case "paid":
            r = "text-success";
            break;
        default:
            r = ""
            break;
    }
    return r
}
export const IsJsonString = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}
export const JsonParseValidate = (data) => {
    if (data && IsJsonString(data)) {
        let json = JSON.parse(data)
        if (typeof json === "object") {
            return json
        } else {
            return []
        }
    } else {
        return []
    }
}
export const getSwishPaymentStatusClass = (key) => {
    let status = "text-danger"
    switch (key) {
        case "paid":
            status = "text-success"
            break
        case "processing":
            status = "text-warning"
            break
        default:
            status = "text-danger"
            break;
    }
    return status
}
export const statusMessage = (status, type) => {
    toast(<Text id={`labels.status_messages.${status}`}>{status}</Text>, { type: toast.TYPE[type] })
}
export const readFileSize = (bytes, si = true, dp = 2) => {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(dp) + ' ' + units[u];
}
export const formatAndSetData = (address) => {
    let country = ''
    let city = ''
    let state = ''
    let full_address = ''
    let zip_code = ''
    if (address.address_components?.length > 0) {
        full_address = address.formatted_address;
        address.address_components.map((obj) => {
            obj.types.map((string) => {
                if (string == "country")
                    country = obj.long_name
                else if (string == "locality" || string == "postal_town")
                    city = obj.long_name
                else if (string == "administrative_area_level_1")
                    state = obj.long_name
                else if (string == "postal_code")
                    zip_code = obj.long_name
            })
        })
    }
    return { state: state, country: country, city: city, zip_code: zip_code, full_address: full_address }
}

// const handleApiLoaded = ({ maps }) => {
//     // use map and maps objects
//     // cLog(map)
//     const Geocoder = new maps.Geocoder();
//     // const addresses = Geocoder.getFromLocation(form.latitude, form.longitude, 1);
//     Geocoder.geocode({ location: { lat: form.latitude, lng: form.longitude } }, (results, status) => {
//         cLog(results)
//         if (status !== maps.GeocoderStatus.OK) {
//             alert(status);
//         }
//         // This is checking to see if the Geoeode Status is OK before proceeding
//         if (status == maps.GeocoderStatus.OK) {
//             // cLog(results);
//             var address = (results[0].formatted_address);
//             cLog(address)
//             formatAndSetData({ results: results })
//         }
//     });
//     cLog(Geocoder)
// };
export function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).trim().toLowerCase());
    // return true
}
export const visitNotification = (n) => {
    let key = n.screen ?? n.module
    let module = String(n.module)
    let type = String(n.type)
    let user = n.user_type
    let re = null
    switch (key) {
        case "market-place-request":
            if (type.includes("Order")) {
                re = getPath("marketplace.orders")
            } else {
                re = getPath("marketplace.listings")
            }
            break;
        case "my_orders":
            re = getPath("myOrders")
            break;
        case "my-jobs":
            if (n?.user_type == "applicant") {
                re = getPath("my-applied-job")
            }
            break;
        case "student-landing":
            if (n?.module == "job") {
                re = getPath("jobView", { id: n?.data_id })
            }
            break;
        case "my-cart":
            re = getPath("cart")
            break;
        case "my-listing":
            if (module === "contest") {
                re = getPath("contests.created")
            } else if (module === "job") {
                re = getPath("my-job")
            } else {
                re = getPath("marketplace.listings")
            }
            break;
        case "posted-jobs":
            re = getPath("jobView", { id: n.data_id })
            break;
        case "package":
            if (module === 'profile')
                re = getPath("myPackages")
            break;
        case "landing_screen":
            if (module === "job")
                re = getPath("jobView", { id: n.data_id })
            else
                getPath("jobs")
            break;
        case "posted-services":
            re = getPath("jobView", { id: n.data_id })
            break;
        case "contest":
            if (n.data_id) {
                if (user === "seller")
                    re = getPath("contests.view", { id: n.data_id })
                else
                    re = getPath("contests.view", { id: n.data_id })
            }
            else {
                if (user === "seller")
                    re = getPath("contests.created")
                else
                    re = getPath("contests.landing")
            }
            break;
        case "joined":
            if (user === "buyer")
                re = getPath("contests.joined", { id: n?.data_id })
            break;
        case "created":
            if (user === "seller")
                re = getPath("contests.created", { id: n?.data_id })
            break;
        default:
            break;
    }
    return re
}

export const encryptUserData = (data) => {
    let bd = { ...data }
    for (const [key, val] of Object.entries(data)) {
        if (Encrypted.hasOwnProperty(key)) {
            if (val)
                bd[key] = Encrypt(val)
        }
    }
    // cLog(bd)
    return bd
}

export const decryptUserData = (data) => {
    let bd = {}
    for (const [key, val] of Object.entries(data)) {
        if (Encrypted.hasOwnProperty(key)) {
            if (isValid(val, null, true)) {
                bd[key] = Decrypt(val)
                // cLog(key, val)
            }
        }
    }
    // cLog("bd", bd)
    // cLog("original", data)
    return { ...data, ...bd }
}

export const getVat = (vat, price) => {
    // let v = price * (vat / 100)
    return 0
}


export function setSwedishNumber(checkMe) {
    if (checkMe) {
        String.prototype.splice = function (idx, rem, str) {
            return this.slice(0, idx) + str + this.slice(idx + Math.abs(rem));
        };
        let temp = checkMe.toString();
        temp = temp.splice(4, 0, ' ')
        temp = temp.splice(8, 0, ' ')
        //temp = temp.splice(9, 0, ' ')
        //temp = temp.splice(11, 0, ' ')
        //temp = temp.splice(12, 0, ' ')
        //temp = temp.splice(0, 0, '+')
        //cLog(temp)
        return temp;
    } else {
        return checkMe
    }
}

export function ReplaceAll(checkMe, toberep, repwith) {
    let temp = checkMe;
    let i = temp?.indexOf(toberep);
    while (i > -1) { //Loop through and replace all instances
        temp = temp.replace(toberep, repwith);
        i = temp.indexOf(toberep);
    }
    return temp;
}
export const minusOneUnlimited = (number) => {
    if (number === -1 || number == "-1" || number == 999) {
        return text("labels.common.unlimited")
    } else if (number === 0 || number == "0") {
        return <i className="fa fa-times"></i>
    } else {
        return number
    }
}
export const zeroToX = (number) => {
    if (number === 0 || number == "0") {
        return <i className="fa fa-times"></i>
    } else {
        return number
    }
}
export const findReason = (id, options) => {
    return options.find(a => a.id === id)
}

export const isEnabled = (module) => {
    let state = store.getState();
    let settings = state.settings
    let enabled = false
    switch (module) {
        case "product":
            if (isValid(settings?.is_product_mod_enabled, 0))
                enabled = true
            break;
        case "service":
            if (isValid(settings?.is_service_mod_enabled, 0))
                enabled = true
            break;
        case "book":
            if (isValid(settings?.is_book_mod_enabled, 0))
                enabled = true
            break;
        case "job":
            if (isValid(settings?.is_job_mod_enabled, 0))
                enabled = true
            break;
        case "contest":
            if (isValid(settings?.is_contest_mod_enabled, 0))
                enabled = true
            break;
        default:
            break;
    }
    return enabled
}
export const setLtr = () => {
    document.body.style.direction = "ltr"
    document.body.classList.remove("rtl")
}
export const setRtl = () => {
    document.body.style.direction = "rtl"
    document.body.classList.add("rtl")
}
export const getUserName = (user) => {
    if (user?.is_minor) {
        if (user?.login_with_guardian_data) {
            return (
                <>
                    <div className='text-truncate'>
                        {user?.first_name}
                        {" "}
                        {user?.last_name}
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <div className='text-truncate'>
                        {user?.guardian_first_name}
                        {" "}
                        {user?.guardian_last_name}
                    </div>
                </>
            )
        }

    } else {
        return (
            <>
                <div className='text-truncate'>
                    {user?.first_name}
                    {" "}
                    {user?.last_name}
                </div>
            </>
        )
    }
}

export const getUserNumber = (user) => {
    if (user?.is_minor) {
        if (user?.login_with_guardian_data) {
            return user?.contact_number
        } else {
            return user?.guardian_contact_number
        }

    } else {
        return user?.contact_number
    }
}

export const getUserMail = (user) => {
    if (user?.is_minor) {
        if (user?.login_with_guardian_data) {
            return user?.email
        } else {
            return user?.guardian_email
        }

    } else {
        return user?.email
    }
}