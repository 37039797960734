import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { cLog } from './helpers/Common';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import { BrowserRouter as Router } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min';
import store from './store';
import LoadingBox from './views/Components/Common/LoadingBox';
const App = lazy(() => import('./App'))

ReactDOM.render(
    <Suspense fallback={
        <>
            {cLog("loading index")}
            <LoadingBox absolute={true} loading={true} />
        </>
    }>
        <Provider store={store}>
            <App />
        </Provider>
    </Suspense>
    ,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
