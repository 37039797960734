import { useEffect, useState } from 'react';
import LocalizedStrings from "react-localization";
import { useSelector } from "react-redux";


const Text = (props) => {
    // get language from redux
    const lang = useSelector(state => state.lang)
    const langData = useSelector(state => state.langData)
    // set text
    const [text, setText] = useState("")
    // get langs
    let translations = new LocalizedStrings(langData);
    // get translation
    const translate = (translations, id) => {
        id = id.replace(/\[["'`](.*)["'`]\]/g, ".$1")
        // cLog(id);
        let text = id.split('.').reduce(function (prev, current) {
            return prev ? prev[current] : undefined
        }, translations)
        return text
    }
    // change lang 
    useEffect(() => {
        translations.setLanguage(lang);
        if (translate(translations, props.id) !== undefined) {
            if (props.data !== undefined) {
                setText(translations.formatString(translate(translations, props.id), props.data))
            } else {
                setText(translate(translations, props.id))
            }
        } else {
            setText(props.children)
        }
    }, [lang, langData])

    // on load
    useEffect(() => {
        translations.setLanguage(lang);
        if (translate(translations, props.id) !== undefined) {
            if (props.data !== undefined) {
                setText(translations.formatString(translate(translations, props.id), props.data))
            } else {
                setText(translate(translations, props.id))
            }
        } else {
            setText(props.children)
        }
    }, [])

    return (
        text ?? null
    );
};

export default Text;