const routes = [
    {
        path: '/',
        exact: true,
        name: 'Home',
        routeName: 'home',
        component: () => (import('../views/Pages/Home'))
    },
    {
        path: '/merge-labels',
        exact: true,
        name: 'm',
        routeName: 'm',
        component: () => (import('../views/Pages/MergeLabels'))
    },
    {
        path: '/email-verification/:email/:token',
        exact: true,
        name: 'Email Verification',
        routeName: 'emailVerification',
        component: () => (import('../views/Pages/EmailVerification'))
    },
    {
        path: '/qr-code/:id?',
        exact: true,
        name: 'QR Code Page',
        routeName: 'qRCodePage',
        component: () => (import('../views/Pages/QRCodePage'))
    },
    {
        path: '/success',
        exact: true,
        name: 'Success',
        routeName: 'success',
        component: () => (import('../views/Pages/Success'))
    },
    {
        path: '/expired',
        exact: true,
        name: 'Stripe Connection Failed, Please Retry',
        routeName: 'expired',
        component: () => (import('../views/Pages/ReConnect'))
    },
    {
        path: '/bam-accept',
        exact: true,
        name: 'Transaction Success',
        routeName: 'BamPaymentSuccess',
        layout: false,
        component: () => (import('../views/Pages/PaymentSuccess'))
    },
    {
        path: '/bam-cancel',
        exact: true,
        layout: false,
        name: 'Transaction Failed',
        routeName: 'BamPaymentFailed',
        component: () => (import('../views/Pages/PaymentFailed'))
    },
    {
        path: '/payment-completed/:id',
        exact: true,
        layout: false,
        name: 'Transaction Success',
        routeName: 'SwishPaymentSuccess',
        component: () => (import('../views/Pages/PaymentSuccess'))
    },
    {
        path: '/payment-canceled/id',
        exact: true,
        layout: false,
        name: 'Transaction Failed',
        routeName: 'SwishPaymentFailed',
        component: () => (import('../views/Pages/PaymentFailed'))
    },
    {
        path: '/about',
        exact: true,
        name: 'About',
        routeName: 'about',
        component: () => (import('../views/Pages/About'))
    },
    {
        path: '/page/about-us',
        exact: true,
        name: 'About Us',
        routeName: 'aboutUs',
        component: () => (import('../views/Pages/AboutUs'))
    },
    {
        path: '/page/why-to-join-us',
        exact: true,
        name: 'Why to join us',
        routeName: 'WhyToJoinUs',
        component: () => (import('../views/Pages/WhyToJoinUs'))
    },
    {
        path: '/faqs',
        exact: true,
        name: 'FAQs',
        routeName: 'faq',
        component: () => (import('../views/Pages/Faq'))
    },
    {
        path: '/contact-us',
        exact: true,
        name: 'Contact Us',
        routeName: 'contactUs',
        component: () => (import('../views/Pages/Contactus'))
    },
    {
        path: '/privacy-policy',
        exact: true,
        name: 'Privacy Policy',
        routeName: 'privacyPolicy',
        component: () => (import('../views/Pages/AllCMS'))
    },
    {
        path: '/why-to-join-us',
        exact: true,
        name: 'Why to join us',
        routeName: 'whyJoin',
        component: () => (import('../views/Pages/AllCMS'))
    },
    {
        path: '/terms-and-conditions',
        exact: true,
        name: 'Terms & Conditions',
        routeName: 'termsAndConditions',
        component: () => (import('../views/Pages/AllCMS'))
    },
    {
        path: '/page/:slug',
        exact: true,
        name: 'Page',
        routeName: 'page',
        component: () => (import('../views/Pages/AllCMS'))
    },
    {
        path: '/ratings-and-reviews/:type/:id',
        exact: true,
        name: 'Ratings and Reviews',
        routeName: 'ratings',
        component: () => (import('../views/Pages/Ratings'))
    },
    {
        path: '/sp-registration',
        exact: true,
        name: 'Service Provider Registration',
        routeName: 'spRegistration',
        component: () => (import('../views/Pages/SPRegistration'))
    },
    {
        path: '/student-registration',
        exact: true,
        name: 'Student Registration',
        routeName: 'studentRegistration',
        component: () => (import('../views/Pages/StudentRegistration'))
    },

    {
        path: '/login',
        name: 'Login',
        exact: true,
        routeName: 'login',
        component: () => (import('../views/Pages/Login'))
    },
    {
        exact: true,
        path: '/forgot-password',
        name: 'Forgot Password',
        routeName: 'forgotPassword',
        component: () => (import('../views/Pages/ForgotPassword'))
    },


    //Product Module
    {
        path: '/cart',
        auth: true,
        exact: true,
        name: 'Cart',
        routeName: 'cart',
        props: { auth: true },
        component: () => (import('../views/Components/ProductsAndServices/Cart'))
    },
    {
        path: '/products',
        auth: false,
        exact: true,
        name: 'Products',
        routeName: 'products',
        props: { module: 'product', auth: true },
        component: () => (import('../views/Components/ProductsAndServices/Products'))
    },
    {
        path: '/products/all/:filter?',
        auth: false,
        exact: true,
        name: 'All Products',
        routeName: 'allProducts',
        props: { module: 'product', auth: true },
        component: () => (import('../views/Components/ProductsAndServices/ProductAndServiceList'))
    },
    {
        path: '/products/all/:filter/:id',
        auth: false,
        exact: true,
        name: 'All Products',
        routeName: 'productsBySeller',
        props: { module: 'product', auth: true },
        component: () => (import('../views/Components/ProductsAndServices/ProductAndServiceList'))
    },
    {
        path: '/services/all/:filter/:id',
        auth: false,
        exact: true,
        name: 'All Products',
        routeName: 'servicesBySeller',
        props: { module: 'service', auth: true },
        component: () => (import('../views/Components/ProductsAndServices/ProductAndServiceList'))
    },
    {
        path: '/books/all/:filter/:id',
        auth: false,
        exact: true,
        name: 'All Products',
        routeName: 'booksBySeller',
        props: { module: 'book', auth: true },
        component: () => (import('../views/Components/ProductsAndServices/ProductAndServiceList'))
    },
    {
        path: '/products/search/:key?',
        auth: false,
        exact: true,
        name: 'All Products',
        routeName: 'allProductsSearch',
        props: { module: 'product', auth: true },
        component: () => (import('../views/Components/ProductsAndServices/ProductAndServiceList'))
    },
    {
        path: '/product/:slug/:id',
        auth: false,
        exact: true,
        name: 'Product Information',
        routeName: 'productInformation',
        props: { module: 'product', auth: true },
        component: () => (import('../views/Components/ProductsAndServices/ProductAndServiceInfo'))
    },
    {
        path: '/services',
        auth: false,
        exact: true,
        name: 'Services',
        routeName: 'services',
        props: { module: 'service', auth: true },
        component: () => (import('../views/Components/ProductsAndServices/Products'))
    },
    {
        path: '/services/all/:filter?',
        auth: false,
        exact: true,
        name: 'All Services',
        routeName: 'allServices',
        props: { module: 'service', auth: true },
        component: () => (import('../views/Components/ProductsAndServices/ProductAndServiceList'))
    },
    {
        path: '/services/search/:key?',
        auth: false,
        exact: true,
        name: 'All Services',
        routeName: 'allServicesSearch',
        props: { module: 'service', auth: true },
        component: () => (import('../views/Components/ProductsAndServices/ProductAndServiceList'))
    },
    {
        path: '/service/:slug/:id',
        auth: false,
        exact: true,
        name: 'Service Information',
        routeName: 'serviceInformation',
        props: { module: 'service', auth: true },
        component: () => (import('../views/Components/ProductsAndServices/ProductAndServiceInfo'))
    },
    {
        path: '/books',
        auth: false,
        exact: true,
        name: 'Books',
        routeName: 'books',
        props: { module: 'book', auth: true },
        component: () => (import('../views/Components/ProductsAndServices/Products'))
    },
    {
        path: '/books/all/:filter?',
        auth: false,
        exact: true,
        name: 'All Books',
        routeName: 'allBooks',
        props: { module: 'book', auth: true },
        component: () => (import('../views/Components/ProductsAndServices/ProductAndServiceList'))
    },
    {
        path: '/books/search/:key?',
        auth: false,
        exact: true,
        name: 'All Books',
        routeName: 'allBooksSearch',
        props: { module: 'book', auth: true },
        component: () => (import('../views/Components/ProductsAndServices/ProductAndServiceList'))
    },
    {
        path: '/book/:slug/:id',
        auth: false,
        exact: true,
        name: 'Book Information',
        routeName: 'bookInformation',
        props: { module: 'book', auth: true },
        component: () => (import('../views/Components/ProductsAndServices/ProductAndServiceInfo'))
    },
    // Marketplace
    {
        path: '/marketplace/products',
        auth: true,
        exact: true,
        name: 'My Listings',
        routeName: 'marketplace.listings',
        props: { module: 'marketplace', auth: true },
        component: () => (import('../views/Components/Marketplace/MyListings'))
    },
    {
        path: '/marketplace/products/add',
        auth: true,
        exact: true,
        name: 'Add Products',
        // layout: false,
        routeName: 'marketplace.addProducts',
        props: { module: 'marketplace', auth: true },
        component: () => (import('../views/Components/Marketplace/AddProducts'))
    },
    {
        path: '/marketplace/products/edit/:id',
        auth: true,
        exact: true,
        name: 'Edit Products',
        routeName: 'marketplace.editProducts',
        props: { module: 'marketplace', auth: true, edit: true },
        component: () => (import('../views/Components/Marketplace/AddProducts'))
    },
    {
        path: '/marketplace/orders',
        auth: true,
        exact: true,
        name: 'Orders',
        routeName: 'marketplace.orders',
        props: { module: 'marketplace', auth: true },
        component: () => (import('../views/Components/Marketplace/Orders'))
    },
    {
        path: '/marketplace/statistics',
        auth: true,
        exact: true,
        name: 'Statistics',
        routeName: 'marketplace.statistics',
        props: { module: 'marketplace', auth: true },
        component: () => (import('../views/Components/Marketplace/Statics'))
    },
    {
        path: '/marketplace/settings',
        auth: true,
        exact: true,
        name: 'Settings',
        routeName: 'marketplace.settings',
        props: { module: 'marketplace', auth: true },
        component: () => (import('../views/Components/Marketplace/Settings'))
    },
    //Job Module
    {
        path: '/jobs',
        auth: false,
        exact: true,
        name: 'Jobs',
        routeName: 'jobs',
        props: { module: 'job' },
        component: () => (import('../views/Components/Jobs/Jobs'))
    },
    {
        path: '/jobs/all/:filter?',
        auth: false,
        exact: true,
        name: 'Jobs View All',
        routeName: 'jobs-all',
        props: { module: 'job' },
        component: () => (import('../views/Components/Jobs/JobViewAll'))
    },
    {
        path: '/jobs/search/:key?',
        auth: false,
        exact: true,
        name: 'Jobs',
        routeName: 'allJobsSearch',
        props: { module: 'job' },
        component: () => (import('../views/Components/Jobs/JobViewAll'))
    },
    {
        path: '/my-applied-job',
        auth: true,
        exact: true,
        name: 'Student My Job',
        routeName: 'my-applied-job',
        props: { module: 'job' },
        component: () => (import('../views/Components/Jobs/StudentMyJob'))
    },
    {
        path: '/my-job',
        auth: true,
        exact: true,
        name: 'Sp My Job',
        routeName: 'my-job',
        props: { module: 'job' },
        component: () => (import('../views/Components/Jobs/SpMyJob'))
    },
    {
        path: '/job-view/:id',
        auth: false,
        exact: true,
        name: 'View job',
        routeName: 'jobView',
        props: { module: 'job' },
        component: () => (import('../views/Components/Jobs/JobView'))
    },
    {
        path: '/job-applicants/:id',
        auth: true,
        exact: true,
        name: 'View Applicants',
        routeName: 'jobApplicants',
        props: { module: 'job' },
        component: () => (import('../views/Components/Jobs/JobApplicants'))
    },
    {
        path: '/job-applicants/viewed',
        auth: true,
        exact: true,
        name: 'View Applicants',
        routeName: 'jobApplicantViewed',
        props: { module: 'job' },
        component: () => (import('../views/Components/Jobs/JobApplicants'))
    },
    {
        path: '/create-job',
        auth: true,
        exact: true,
        name: 'Create Job',
        routeName: 'jobCreate',
        props: { module: 'job' },
        component: () => (import('../views/Components/Jobs/JobCreate'))
    },
    {
        path: '/edit-job/:id',
        auth: true,
        exact: true,
        name: 'Job Edit',
        routeName: 'jobEdit',
        props: { module: 'job' },
        component: () => (import('../views/Components/Jobs/JobEdit'))
    },
    {
        path: '/students',
        auth: true,
        exact: true,
        name: 'Student',
        routeName: 'students',
        props: { module: 'job' },
        component: () => (import('../views/Components/Jobs/Students'))
    },
    {
        path: '/student-view/:id',
        auth: true,
        exact: true,
        name: 'Student Information',
        routeName: 'student-view',
        props: { module: 'job' },
        component: () => (import('../views/Components/Jobs/StudentView'))
    },
    {
        path: '/applicants/all/:filter?',
        auth: true,
        exact: true,
        name: 'AllApplicants',
        routeName: 'allApplicants',
        props: { module: 'job' },
        //props: { module: 'service', auth: true },
        component: () => (import('../views/Components/Jobs/ApplicantsViewAll'))
    },
    {
        path: '/applicants-home',
        auth: true,
        exact: true,
        name: 'ApplicantsHome',
        routeName: 'applicantsHome',
        props: { module: 'job' },
        component: () => (import('../views/Components/Jobs/ApplicantsHome'))
    },

    //Setting
    {
        path: '/personal-information',
        auth: true,
        exact: true,
        name: 'Personal Information',
        routeName: 'personalInformation',
        component: () => (import('../views/Components/Setting/PersonalInformation'))
    },
    {
        path: '/stripe-integration',
        auth: true,
        exact: true,
        name: 'Stripe Integration',
        routeName: 'stripeIntegration',
        component: () => (import('../views/Components/Setting/StripeInformation'))
    },
    {
        path: '/edit-profile',
        auth: true,
        exact: true,
        name: 'Edit Profile',
        routeName: 'edit-profile',
        component: () => (import('../views/Components/Setting/EditProfile'))
    },
    {
        path: '/address-management',
        auth: true,
        exact: true,
        name: 'Address Management',
        routeName: 'addressManagement',
        component: () => (import('../views/Components/Setting/AddressManagement'))
    },
    {
        path: '/card-management',
        auth: false,
        exact: true,
        name: 'Card Management',
        routeName: 'cardManagement',
        component: () => (import('../views/Components/Setting/CardManagement'))
    },
    {
        path: '/my-packages',
        auth: true,
        exact: true,
        name: 'My Packages',
        routeName: 'myPackages',
        component: () => (import('../views/Components/Setting/MyPackages'))
    },
    {
        path: '/my-favourites',
        auth: true,
        exact: true,
        name: 'My Favourites',
        routeName: 'myFavourites',
        component: () => (import('../views/Components/Setting/MyFavourites'))
    },
    {
        path: '/add-address',
        auth: true,
        exact: true,
        name: 'Add Address',
        routeName: 'addAddress',
        component: () => (import('../views/Components/Setting/AddAddress'))
    },
    {
        path: '/my-cv',
        auth: true,
        exact: true,
        name: 'My CV',
        routeName: 'myCv',
        component: () => (import('../views/Components/Setting/EditCV'))
    },
    // my orders
    {
        path: '/my-orders',
        auth: true,
        exact: true,
        name: 'My Orders',
        routeName: 'myOrders',
        component: () => (import('../views/Components/ProductsAndServices/MyOrders'))
    },
    {
        path: '/order/success/:id',
        auth: true,
        exact: true,
        name: 'Orders Successful',
        routeName: 'orderSuccess',
        component: () => (import('../views/Components/ProductsAndServices/OrderSuccess'))
    },
    // notifications
    {
        path: '/notifications',
        auth: true,
        exact: true,
        name: 'Notifications',
        routeName: 'notifications',
        component: () => (import('../views/Components/Setting/Notifications'))
    },
    //plan
    {
        path: '/packages',
        //auth: true,
        exact: true,
        name: 'Packages',
        routeName: 'packages',
        component: () => (import('../views/Pages/Plan'))
    },

    // Contest Module
    // my contest / events
    {
        path: '/contests/my-contest',
        auth: true,
        exact: true,
        name: 'My Contest',
        routeName: 'contests.created',
        props: { module: 'contest', auth: true },
        component: () => (import('../views/Components/Contests/Created'))
    },
    // create contest
    {
        path: '/contests/create/contest',
        auth: true,
        exact: true,
        name: 'Create Contest',
        routeName: 'contests.create.contest',
        props: { module: 'contest', auth: true },
        component: () => (import('../views/Components/Contests/Create'))
    },
    // create event
    {
        path: '/contests/create/event',
        auth: true,
        exact: true,
        name: 'Create Event',
        routeName: 'contests.create.event',
        props: { module: 'event', auth: true },
        component: () => (import('../views/Components/Contests/Create'))
    },
    // edit contest
    {
        path: '/contests/edit/contest/:id',
        auth: true,
        exact: true,
        name: 'Edit Contest',
        routeName: 'contests.edit.contest',
        props: { module: 'contest', edit: true, auth: true },
        component: () => (import('../views/Components/Contests/Create'))
    },
    // edit event
    {
        path: '/contests/edit/event/:id',
        auth: true,
        exact: true,
        name: 'Edit Event',
        routeName: 'contests.edit.event',
        props: { module: 'event', edit: true, auth: true },
        component: () => (import('../views/Components/Contests/Create'))
    },
    // contest / event landing
    {
        path: '/contest',
        auth: false,
        exact: true,
        name: 'Contests',
        routeName: 'contests.landing',
        props: { module: 'contests', type: 'company', },
        component: () => (import('../views/Components/Contests/Landing'))
    },
    {
        path: '/event',
        auth: false,
        exact: true,
        name: 'Contests',
        routeName: 'events.landing',
        props: { module: 'events', type: 'company' },
        component: () => (import('../views/Components/Contests/Landing'))
    },
    {
        path: '/students-contest',
        auth: false,
        exact: true,
        name: 'Contests',
        routeName: 'contests.landing.student',
        props: { module: 'contests', type: 'student', },
        component: () => (import('../views/Components/Contests/Landing'))
    },
    {
        path: '/students-event',
        auth: false,
        exact: true,
        name: 'Contests',
        routeName: 'events.landing.student',
        props: { module: 'events', type: 'student' },
        component: () => (import('../views/Components/Contests/Landing'))
    },
    // All Contest
    {
        path: '/contest/all/:filter?',
        auth: false,
        exact: true,
        name: 'All Contest',
        routeName: 'contests.all',
        props: { module: 'contest', auth: true },
        component: () => (import('../views/Components/Contests/Contests'))
    },
    {
        path: '/contest/category/:category',
        auth: false,
        exact: true,
        name: 'All Contest',
        routeName: 'contests.category',
        props: { module: 'contest', auth: true },
        component: () => (import('../views/Components/Contests/Contests'))
    },
    {
        path: '/contest/sub_category/:sub_category',
        auth: false,
        exact: true,
        name: 'All Contest',
        routeName: 'contests.sub_category',
        props: { module: 'contest', auth: true },
        component: () => (import('../views/Components/Contests/Contests'))
    },
    {
        path: '/contest/search/:key?',
        auth: false,
        exact: true,
        name: 'All Contest',
        routeName: 'allContestsSearch',
        props: { module: 'contest', auth: true },
        component: () => (import('../views/Components/Contests/Contests'))
    },
    {
        path: '/event/search/:key?',
        auth: false,
        exact: true,
        name: 'All Event',
        routeName: 'allEventsSearch',
        props: { module: 'event', auth: true },
        component: () => (import('../views/Components/Contests/Contests'))
    },

    // contest details
    {
        path: '/contest/:id',
        auth: false,
        exact: true,
        name: 'Contest Details',
        routeName: 'contests.view',
        props: { module: 'contest', auth: true },
        component: () => (import('../views/Components/Contests/View'))
    },
    {
        path: '/event/:id',
        auth: false,
        exact: true,
        name: 'Event Details',
        routeName: 'events.view',
        props: { module: 'event', auth: true },
        component: () => (import('../views/Components/Contests/View'))
    },
    // buy products
    {
        path: '/p/buy/:id',
        auth: true,
        exact: true,
        name: 'Buy Product',
        routeName: 'product.buyNow',
        props: { module: 'product' },
        component: () => (import('../views/Components/ProductsAndServices/BuyNow'))
    },
    // buy contest
    {
        path: '/buy/:type/:id',
        auth: true,
        exact: true,
        name: 'Contest Details',
        routeName: 'contests.buy',
        props: { module: 'contest' },
        component: () => (import('../views/Components/Contests/BuyContest'))
    },

    // contest participants
    {
        path: '/contest/participants/:id',
        auth: true,
        exact: true,
        name: 'Contest Participants',
        routeName: 'contests.participants',
        props: { module: 'contest', auth: true },
        component: () => (import('../views/Components/Contests/Participants'))
    },
    // contest joined
    {
        path: '/my-contest',
        auth: true,
        exact: true,
        name: 'Joined Contest',
        routeName: 'contests.joined',
        props: { module: 'contest', auth: true },
        component: () => (import('../views/Components/Contests/Joined'))
    },
    // rewards
    {
        path: '/my-rewards',
        auth: true,
        exact: true,
        name: 'My Rewards',
        routeName: 'myRewards',
        props: { auth: true },
        component: () => (import('../views/Components/Setting/Rewards'))
    },
    // service provider details
    {
        path: '/service-provider/:id',
        auth: false,
        exact: true,
        name: 'Service Provider',
        routeName: 'serviceProviderDetail',
        props: { auth: false },
        component: () => (import('../views/Components/ServiceProvider/ServiceProviderDetails'))
    },
    // messages
    {
        path: '/messages',
        auth: true,
        exact: true,
        name: 'Messages',
        routeName: 'messages',
        props: { auth: true },
        component: () => (import('../views/Components/Setting/Messages'))
    },
    {
        path: '/buyer-registration',
        auth: false,
        exact: true,
        name: 'Buyer Registration',
        routeName: 'buyerRegister',
        props: { auth: false },
        component: () => (import('../views/Pages/BuyerRegister'))
    },
    {
        path: '/cool-company-dashboard',
        auth: false,
        exact: true,
        name: 'Cool Company Dashboard',
        routeName: 'coolCompany',
        props: { auth: true },
        component: () => (import('../views/Components/Setting/CoolCompany'))
    },
    //Not found
    {
        name: '404',
        routeName: 'notFound',
        component: () => (import('../views/Pages/NotFound'))
    }
];

export default routes;
